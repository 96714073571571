import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from "react-router-dom"; // v6
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Swal from 'sweetalert2';
import CustomErrorMsg from "../../components/frontend/CustomErrorMsg";
import {BASE_URL, GetCardType } from '../Config'
import moment from "moment";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import '../../assests/Lander/assets/css/skincare6pLead.css';
import useStore from '../../store';
import { Helmet } from 'react-helmet';

// Images 
import checkProduct1 from '../../assests/Lander/assets/images/skincare6p/productImg-check.png';
import checkProduct2 from '../../assests/Lander/assets/images/skincare6p/productImg-check2.png';
import checkProduct3 from '../../assests/Lander/assets/images/skincare6p/productImg-check3.png';
import moneyBckSeal from '../../assests/Lander/assets/images/skincare6p/MoneyBackSeal.png';
import testimonial1 from '../../assests/Lander/assets/images/skincare6p/skincare-img-42.png';
import testimonial2 from '../../assests/Lander/assets/images/skincare6p/skincare-img-56.png';
import card_img from '../../assests/Lander/assets/images/skincare6p/card_img.png';
import cvv2_location from '../../assests/Lander/assets/images/skincare6p/cvv2-location.png';
import trustBadge from '../../assests/Lander/assets/images/skincare6p/trust_Badges.png';
import Arrow2 from '../../assests/admin/assets/img/arrow2.png';
import paypal from '../../assests/frontend/assets/images/paypal.png';
import paypalBtn from '../../assests/frontend/assets/images/paypal-btn.png';
import PaymentButton from '../../assests/admin/assets/img/payment_button.png';
import Copyright from '../Copyright';
// import verify from '../../assests/Lander/assets/images/skincare6p/verify.png';



function Skincare6pCheckout(props){
    const [loadershow, setloadershow] = useState(false);
    const afterLogin = useStore((state) => state.afterLogin);
    const currentYear = new Date().getFullYear();
    const years = Array.from(new Array(10), (val, index) => currentYear + index);
    const [isUpdateButtonClicked, setIsUpdateButtonClicked] = useState(false);

    // 10 min countdown 
    const [seconds, setSeconds] = useState(600);
    const [pack, setPack] = useState(localStorage.getItem('pack'));
    const [checkoutFunnelData, setCheckoutFunnelData] = useState({});
    const [SMToggle, setSMToggle] = useState(false);
    const [SMAddonToggle, setSMAddonToggle] = useState(true);
    const [selectedProduct, setSelectedProduct] = useState(1);
    const [states, setStates] = useState([]);
    const [showDiv, setShowDiv] = useState(false);
    const [checkboxValue, setcheckboxValue] = useState(true);
    const location = useLocation();
    const [cardType, setCardType] = useState("");
    const [key, setKey] = useState(localStorage.getItem("pack"));

    // affiliate parameter set
    let affid = new URLSearchParams(location.search).get('affid');
    let sid = new URLSearchParams(location.search).get('sid');
    let s1 = new URLSearchParams(location.search).get('s1');
    let s2 = new URLSearchParams(location.search).get('s2');
    let s3 = new URLSearchParams(location.search).get('s3');
    let click_id = new URLSearchParams(location.search).get('click_id');
    let notes = new URLSearchParams(location.search).get('notes');


    // URL slug related
    let localURL = window.location.origin.includes('localhost') ? 'http://localhost:3000/' : window.location.origin+'/';
    let URL = window.location.href;
    let url_slug = URL.substring(URL.lastIndexOf('/') + 0);
    let indexOfQuestionMark = url_slug.indexOf('?');
    let urlSlug;
    if (indexOfQuestionMark !== -1) {
        urlSlug = url_slug.substring(0, indexOfQuestionMark);
    } else {
        urlSlug = url_slug;
    }
    let params = location.search;

    // product related data
    const [price, setPrice] = useState(0);
    const [retail, setReatil] = useState(0);
    const [save, setSave] = useState(0);
    const [total, setTotal] = useState(0);
    const [shipping, setShipping] = useState(0);
    const [sapphirePrice, setSapphirePrice] = useState(0.00);
    const [pID, setPID] = useState(0);
    const [shippingDiv, setShippingDiv] = useState(false);
    const [shipCountry, setShipCountry] = useState('239');
    const [shipState, setShipState] = useState('');

    // Toggle 
    const [isOpen, setIsOpen] = useState(false);
    const [isTermOpen, setTermOpen] = useState(false);

    //payment section
    const [selectedPayment, setSelectedPayment] = useState('creditCard');
    const handlePaymentSelection = (value) => {
        setSelectedPayment(value);
    };

    // billing same as shipping checkbox
    const handleCheckboxChange = (event) => {
        setcheckboxValue(event.target.checked);
        setShowDiv(!event.target.checked);
    };

    const handleCountryChange = (event) => {
        const country_id = event.target.value;
        axios.get(`api/get-state/${country_id}`).then(res => {
            if(res.data.status === true) {
                setStates(res.data.data);
            }
        })
    };

    const handleCountryChange2 = (country_id) => {
        axios.get(`api/get-state/${country_id}`).then(res => {
            if(res.data.status === true) {
                setStates(res.data.data);
            }
        })
    };

    function setCreditCardType(CCNumber){
        if(CCNumber.length>=15){
            setCardType(GetCardType(CCNumber));
            document.getElementById('card_type').value = GetCardType(CCNumber);
        }
    }

    useEffect(() => {
        const interval = setInterval(() => {
        if (seconds > 0) {
            setSeconds((prevSeconds) => prevSeconds - 1);
        }
        }, 1000);

        return () => clearInterval(interval);
    }, [seconds]);

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    function handleSelect(key) {
        setKey(key);
        localStorage.setItem("pack",key);
        if(key === '1'){
            setSelectedProduct(1);
            if(!SMAddonToggle){
                setPrice(props.data[0].third_product_price);
                setSave(props.data[0].third_product_save);
                setShipping(9.95);
                setTotal(parseFloat(props.data[0].third_product_price)+parseFloat(9.95));
                setReatil(parseFloat(props.data[0].third_product_price)+parseFloat(9.95));
                setPID(props.data[0].third_product_id);
                setShippingDiv(true);
                localStorage.setItem('ProductId',198);
            } else {
                setPrice(props.data[0].third_product_price);
                setSave(props.data[0].third_product_save);
                setShipping(0);
                setTotal(parseFloat(props.data[0].third_product_price)+parseFloat(0.00));
                setReatil(parseFloat(props.data[0].third_product_price)+parseFloat(0.00));
                setPID(props.data[0].third_product_id);
                setShippingDiv(false);
                localStorage.setItem('ProductId',props.data[0].third_product_id);
            }
        } else if(key === '2') {
            setSelectedProduct(2);
            setShippingDiv(false);
            localStorage.setItem('ProductId',props.data[0].second_product_id);

            setPrice(props.data[0].second_product_price);
            setReatil(props.data[0].second_product_retail);
            setSave(props.data[0].second_product_save);
            setShipping(0);
            setTotal(parseFloat(props.data[0].second_product_price)+parseFloat(0.00));
            setPID(props.data[0].second_product_id);
        } else if(key === '3') {
            setSelectedProduct(3);
            setShippingDiv(false);
            localStorage.setItem('ProductId',props.data[0].first_product_id);

            setPrice(props.data[0].first_product_price);
            setReatil(props.data[0].first_product_retail);
            setSave(props.data[0].first_product_save);
            setShipping(0);
            setTotal(parseFloat(props.data[0].first_product_price)+parseFloat(0.00));
            setPID(props.data[0].first_product_id);
        }
    }
    

    function setIntialData() {
        setCheckoutFunnelData(props.data);
        if(localStorage.getItem("pack") === "1"){
            setPID((props.data[0].third_product_id));
            setPrice(props.data[0].third_product_price);
            setReatil(props.data[0].third_product_retail);
            setSave(props.data[0].third_product_save);
            setShipping(0);
            setTotal(parseFloat((props.data[0].third_product_price)) + parseFloat(shipping));
            localStorage.setItem('ProductId',props.data[0].third_product_id);
        } else if(localStorage.getItem("pack") === "2") {
            setPID((props.data[0].second_product_id));
            setPrice(props.data[0].second_product_price);
            setReatil(props.data[0].second_product_retail);
            setSave(props.data[0].second_product_save);
            setShipping(0);
            setTotal(parseFloat((props.data[0].second_product_price)) + parseFloat(shipping));
            localStorage.setItem('ProductId',props.data[0].second_product_id);
        } else if(localStorage.getItem("pack") === "3") {
            setPID((props.data[0].first_product_id));
            setPrice(props.data[0].first_product_price);
            setReatil(props.data[0].first_product_retail);
            setSave(props.data[0].first_product_save);
            setShipping(0);
            setTotal(parseFloat((props.data[0].first_product_price)) + parseFloat(shipping));
            localStorage.setItem('ProductId',props.data[0].first_product_id);
        }    
    }

    function handleSPAddon() {
        setSMAddonToggle(!SMAddonToggle);
        if (localStorage.getItem('pack') === '1') {
            if (!SMAddonToggle) {
                setShipping(0);
                setShippingDiv(false);
                setTotal(parseFloat(props.data[0].third_product_price)+parseFloat(0.00));
                setReatil(parseFloat(props.data[0].third_product_price)+parseFloat(0.00));
                localStorage.setItem('ProductId',checkoutFunnelData[0].third_product_id);
            } else {
                setShipping(9.95);
                setShippingDiv(true);
                setTotal(parseFloat(props.data[0].third_product_price)+parseFloat(9.95));
                setReatil(parseFloat(props.data[0].third_product_price)+parseFloat(9.95));
                localStorage.setItem('ProductId',198);
            }
        }
    }

    useEffect(() => { 
        setIntialData();
        handleCountryChange2(shipCountry);
    }, [shipCountry]);

    //PayPalSubmit Handler
    function PayPalHandler(values){
        if (!isUpdateButtonClicked) {
            setIsUpdateButtonClicked(true);
            if(localStorage.getItem('firstName')){
                let product_id = localStorage.getItem('ProductId');
                let is_membership = '';
                if (checkoutFunnelData[0].membership_add_on === 1) {
                    if (SMAddonToggle) {
                        is_membership = 41;
                        localStorage.setItem("is_membership",41);
                        localStorage.setItem('combine_product', 1);
                        localStorage.setItem('combine_product_id', 177); 
                    }
                    else {
                        is_membership = 0;
                        localStorage.setItem("is_membership",0);
                    }
                }
                else {
                    is_membership = 0;
                }

                let firstName = localStorage.getItem('firstName');
                let lastName = localStorage.getItem('lastName');
                let address = localStorage.getItem('shippingAddress1');
                let zip = localStorage.getItem('shippingZip');
                let state = localStorage.getItem('shippingState');
                let city = localStorage.getItem('shippingCity');
                let phone = localStorage.getItem('phone');
                let email = localStorage.getItem('email');
                let country = localStorage.getItem('country');
                let alt_pay_url = localURL+localStorage.getItem('up1_s');
                let prospectId = (localStorage.getItem('prospectId') ? localStorage.getItem('prospectId') : '');
                let combine_product = localStorage.getItem('combine_product');
                let combine_product_id = localStorage.getItem('combine_product_id');
                let funnel = 'skincare6p';
                let billingSameAsShipping = values.billingSameAsShipping;
                let shipping_address = '';
                let shipping_city = '';
                let shipping_country = '';
                let shipping_state = '';
                let shipping_zip = '';
                if(billingSameAsShipping === "NO"){
                    shipping_address = values.shipping_address;
                    shipping_city = values.shipping_city;
                    shipping_country = values.shipping_country === '41' ? 'CA' : 'US';
                    shipping_state = values.shipping_state;
                    shipping_zip = values.shipping_zip;
                } else {
                    shipping_address = address;
                    shipping_city = city;
                    shipping_country = country;
                    shipping_state = state;
                    shipping_zip = zip;
                }

                /* affiliate params*/
                let affid = new URLSearchParams(location.search).get('affid');
                let sid = new URLSearchParams(location.search).get('sid');
                let s1 = new URLSearchParams(location.search).get('s1');
                let s2 = new URLSearchParams(location.search).get('s2');
                let s3 = new URLSearchParams(location.search).get('s3');
                let click_id = new URLSearchParams(location.search).get('click_id');
                let notes = new URLSearchParams(location.search).get('notes');

                localStorage.setItem('affid',affid);
                localStorage.setItem('sid',sid);
                localStorage.setItem('s1',s1);
                localStorage.setItem('s2',s2);
                localStorage.setItem('s3',s3);
                localStorage.setItem('click_id',click_id);
                localStorage.setItem('notes',notes);
                localStorage.setItem('funnel',funnel);

                
                window.location.href=BASE_URL+
                'whc-custom/public/api/paypal?product_id='+product_id+
                '&is_membership='+is_membership+
                '&firstName='+firstName+
                '&lastName='+lastName+
                '&address='+address+
                '&zip='+zip+
                '&state='+state+
                '&city='+city+
                '&phone='+phone+
                '&email='+email+
                '&country='+country+
                '&alt_pay_url='+alt_pay_url+
                '&affid='+affid+
                '&sid='+sid+
                '&s1='+s1+
                '&s2='+s2+
                '&s3='+s3+
                '&click_id='+click_id+
                '&notes='+notes+
                '&prospectId='+prospectId+
                '&combine_product='+combine_product+
                '&combine_product_id='+combine_product_id+
                '&funnel='+funnel+
                '&billingSameAsShipping='+billingSameAsShipping+
                '&shipping_address='+shipping_address+
                '&shipping_city='+shipping_city+
                '&shipping_country='+shipping_country+
                '&shipping_state='+shipping_state+
                '&shipping_zip='+shipping_zip;
            } else {
                setIsUpdateButtonClicked(false);
                Swal.fire({
                    title: "Please create prospect",
                    showCancelButton: false,
                    showConfirmButton: true,
                    confirmButtonText: 'OK',
                    cancelButtonText: "Cancel",
                    confirmButtonColor: '#7066e0',
                    cancelButtonColor: '#18aebf',
                    showCloseButton: true,
        
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.href=BASE_URL+'skincare6p';
                    }
                })
            }
        }
        
    }

    // credit card submit
    const confirmSubmit = (data) => {
        if (!isUpdateButtonClicked) {
            setIsUpdateButtonClicked(true);
            setloadershow(true);
            let expirationDate = data.cc_month+data.cc_year.slice(-2);
            localStorage.setItem('method','normal');
            const formdata = new FormData();
            formdata.append('product_id', localStorage.getItem('ProductId'));
            if (checkoutFunnelData[0].membership_add_on === 1) {
                if (SMAddonToggle) {
                    formdata.append('is_membership', 41);
                    formdata.append('combine_product', 1);
                    formdata.append('combine_product_id', 177); 
                }
                else {
                    formdata.append('is_membership', 0);
                }
            }
            else {
                formdata.append('is_membership', 0);
            }

            formdata.append('first_name', localStorage.getItem('firstName'));
            formdata.append('last_name', localStorage.getItem('lastName'));
            formdata.append('address', localStorage.getItem('shippingAddress1'));
            formdata.append('zip', localStorage.getItem('shippingZip'));
            formdata.append('state', localStorage.getItem('shippingState'));
            formdata.append('city', localStorage.getItem('shippingCity'));
            formdata.append('phone', localStorage.getItem('phone'));
            formdata.append('email', localStorage.getItem('email'));
            formdata.append('country', localStorage.getItem('country'));

            formdata.append('creditCardNumber', data.ccnumber);
            formdata.append('expirationDate', expirationDate);
            formdata.append('cvv', data.cvv);
            formdata.append('creditCardType', data.card_type);
            formdata.append('affid', data.affid);
            formdata.append('sid', data.sid);
            formdata.append('s1', data.s1);
            formdata.append('s2', data.s2);
            formdata.append('s3', data.s3);
            formdata.append('click_id', data.click_id);
            formdata.append('notes', data.notes);

            formdata.append('billingSameAsShipping', data.billingSameAsShipping);
            formdata.append('shipping_address', data.shipping_address);
            formdata.append('shipping_city', data.shipping_city);
            formdata.append('shipping_state', data.shipping_state);
            formdata.append('shipping_zip', data.shipping_zip);
            formdata.append('shipping_country', data.shipping_country === '41' ? 'CA' : 'US');

            if(localStorage.getItem('prospectId')){
                formdata.append('prospectId', localStorage.getItem('prospectId'));
            }
            formdata.append('method',localStorage.getItem('method'));
            formdata.append('funnel', 'skincare6p');
            localStorage.setItem('pixel','facebook');
            localStorage.setItem('total', total);
            localStorage.setItem('affid', data.affid);
            localStorage.setItem('sid', data.sid);
            localStorage.setItem('s1', data.s1);
            localStorage.setItem('s2', data.s2);
            localStorage.setItem('s3', data.s3);
            localStorage.setItem('click_id', data.click_id);
            localStorage.setItem('notes', data.notes);

            //console.log(Object.fromEntries(formdata));
            axios.post(`api/place-funnel-order-with-prospect`, formdata).then(res => {
                if (res.data.status === true) {
                    //console.log(res.data.data);
                    sessionStorage.setItem('yp', res.data.data.user_info.yp);
                    localStorage.setItem('orderId', res.data.data.order_id);
                    localStorage.setItem('membership_orderId', res.data.membership ? res.data.membership.order_id : false);
                    localStorage.setItem('authToken', res.data.data.member_token);
                    localStorage.setItem('permission', res.data.data.user_info.account_permission);
                    localStorage.setItem('userId', res.data.data.user_info.user_id);
                    localStorage.setItem('userEmal', res.data.data.user_info.email);


                    axios.get(`api/check_user_membership/${localStorage.getItem('email')}`).then(result => {
                        setIsUpdateButtonClicked(false);
                        let is_membership = 0;
                        if(parseInt(result.data) === 1){
                            is_membership = 1;
                        } else {
                            is_membership = 0;
                        }
                        afterLogin(res.data.data.user_info.account_permission, res.data.data.user_info.user_type,res.data.data.user_info.user_id,res.data.data.user_info,is_membership);
                        if (localStorage.getItem('up1_s')) {
                            window.location.href = '/' + localStorage.getItem('up1_s')+params;
                        }
                        else {
                            window.location.href = '/' + localStorage.getItem('con_s')+params;
                        }
                    });
                }
                else {
                    setShipCountry(data.shipping_country);
                    setShipState(data.shipping_state);
                    setIsUpdateButtonClicked(false);
                    setloadershow(false);
                    Swal.fire({
                        title: res.data.message,
                        icon: 'warning',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33'
                    })
                }
            })
        }

    }

    return (
        <>
            <div className={loadershow ? 'loading' : 'd-none'}></div>
            <Helmet>
                <script>
                    {`
                        !function(f,b,e,v,n,t,s)
                        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                        n.queue=[];t=b.createElement(e);t.async=!0;
                        t.src=v;s=b.getElementsByTagName(e)[0];
                        s.parentNode.insertBefore(t,s)}(window, document,'script',
                        'https://connect.facebook.net/en_US/fbevents.js');
                        fbq('init', '1440871839866690');
                        fbq('track', 'PageView');
                        fbq('track', 'InitiateCheckout');
                    `}
                </script>
                <noscript>
                    {`
                        <img 
                            height="1" 
                            width="1" 
                            style={{display: 'none'}}
                            src="https://www.facebook.com/tr?id=1440871839866690&ev=PageView&noscript=1"
                        />
                    `}
                </noscript>
            </Helmet>
            <div className="skincare6pLead_wrapper">
                <div className="skincare6pLead_container container">
                    <div className="resize1">
                        <div className="stepwizard2">
                            <div className="stepwizard-row2 step-wizard-2">
                                <ul className='row'>
                                    <li className="active bsFlexWrapClass col-xs-6">
                                        <div className="stepwizard-step2-div">
                                            <div className="stepwizard-step2">
                                                <a href="#" className="btn btn-circle active-step btn-primary">COMPLETE ORDER </a>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="bsFlexWrapClass col-xs-6">
                                        <div className="stepwizard-step2-div">
                                            <div className="stepwizard-step2">
                                                <a href="#" className="btn btn-default deactive btn-circle">SUMMARY</a>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="skincareCheckStep step-2">
                            <div className="row">
                                {/* Col Begin 1 */}
                                <div className="col-lg-8">
                                    {/* Top Offer  */}
                                    <div className="tab_offer">
                                        <div className="row">
                                            <div className="col-7 text-center">
                                                <p>Your order is reserved for :</p>
                                            </div>
                                            <div className="col-3 text-center">
                                                <p><span className="count-up">{formatTime(seconds)}</span> </p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Top Offer end  */}
                                    <div className="tracking_offer_col">
                                        <h1 className='col_title'><span>Great Job! </span> You’re taking your first step towards a firmer and more sculpted neckline. Act now so you don’t miss out on this offer!</h1>
                                        <div className="availabilty_col">
                                            <div className="row bsFlexWrapClass">
                                                <div className="col-md-7 text-center">
                                                    <p>Current Availability:</p>
                                                    <div className="meter_col">
                                                        <div className="low"></div>
                                                    </div>
                                                    <span className="meter_text">Low</span>
                                                </div>
                                                <div className="col-md-5 text-center">
                                                    <p>Sell Out Risk: </p><span className="meter_text">High</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="txt-red chk-premium">
                                            <p><strong>PREMIUM UPGRADE:</strong> We are upgrading the formulation for even faster and more dramatic results!</p>
                                        </div>
                                        {/* Selected Package : Begin  */}
                                        <div className="selected_package">
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <h2>Your Selected Package</h2>
                                                </div>
                                                <div className="col-md-12 tabed_area col-xs-12 pull-right text-center">
                                                <Tabs
                                                    defaultActiveKey={key}
                                                    id="uncontrolled-tab-example"
                                                    className="mb-3"
                                                    onSelect={handleSelect}
                                                    >
                                                        <Tab eventKey={1} title="1 Jar - $69">
                                                            <div className="product_info tab_cont ">
                                                                <div className="row">
                                                                    <div className="col-md-7">
                                                                        <div className="product_img text-center">
                                                                            <img src={checkProduct1} alt='' className='' />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-5">
                                                                        <div className="product_info__div">
                                                                            <h1 className='pricePerJar'><span>$69</span>/JAR</h1>
                                                                            <h2 className="sale">WAS : <span id="iyqdwf" className="sale_price">USD <span id="ccdynamiclabel127">$69.00</span></span> </h2>
                                                                            <h2 className="regular">NOW : USD <span id="ccdynamiclabel131">$69</span> </h2>
                                                                            <h2 className="free">( Free Shipping ) </h2>
                                                                        </div>
                                                                    </div>
                                                                </div>                                                            
                                                            </div>
                                                        </Tab>
                                                        <Tab eventKey={2} title="3 Jars - $99">
                                                            <div className="product_info tab_cont ">
                                                                <div className="row">
                                                                    <div className="col-md-7">
                                                                        <div className="product_img text-center">
                                                                            <img src={checkProduct2} alt='' className='' />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-5">
                                                                        <div className="product_info__div">
                                                                            <h1 className='pricePerJar'><span>$33.66</span>/JAR</h1>
                                                                            <h2 className="sale">WAS : <span id="iyqdwf" className="sale_price">USD <span id="ccdynamiclabel127">$269.00</span></span> </h2>
                                                                            <h2 className="regular">NOW : USD <span id="ccdynamiclabel131">$99</span> </h2>
                                                                            <h2 className="free">( Free Shipping ) </h2>
                                                                        </div>
                                                                    </div>
                                                                </div>                                                            
                                                            </div>
                                                        </Tab>
                                                        <Tab eventKey={3} title="5 Jars - $149">
                                                            <div className="product_info tab_cont ">
                                                                <div className="row">
                                                                    <div className="col-md-7">
                                                                        <div className="product_img text-center">
                                                                            <img src={checkProduct3} alt='' className='' />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-5">
                                                                        <div className="product_info__div">
                                                                            <h1 className='pricePerJar'><span>$29.60</span>/JAR</h1>
                                                                            <h2 className="sale">WAS : <span id="iyqdwf" className="sale_price">USD <span id="ccdynamiclabel127">$449.00</span></span> </h2>
                                                                            <h2 className="regular">NOW : USD <span id="ccdynamiclabel131">$149</span> </h2>
                                                                            <h2 className="free">( Free Shipping ) </h2>
                                                                        </div>
                                                                    </div>
                                                                </div>                                                            
                                                            </div>
                                                        </Tab>
                                                    </Tabs>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Selected Package : End  */}
                                        <div className="order-counter">
                                            <article className="clearfix">
                                                <div className="left">Shipping Type:</div>
                                                <div id="block2_shipping" className="right"><b>{shipping == 0 ? <b>FREE</b> : null} </b> Priority Shipping</div>
                                                <div className="clear">&nbsp;</div>
                                            </article>
                                            <article className="clearfix">
                                                <div className="left">Shipping Price:</div>
                                                <div id="block2_price" className="right"><b>${shipping.toFixed(2)}</b></div>
                                                <div className="clear">&nbsp;</div>
                                            </article>
                                            <article className="clearfix">
                                                <div className="left">Your Total:</div>
                                                <div id="block2_total" className="right">${total.toFixed(2)}</div>
                                                <div className="clear">&nbsp;</div>
                                            </article>
                                            <article className="last clearfix">
                                                <div id="block2_total2" className="left">Retail: ${parseFloat(retail).toFixed(2)}</div>
                                                <div id="block2_save" className="right"><b>You Save: ${parseFloat(save).toFixed(2)}</b></div>
                                                <div className="clear">&nbsp;</div>
                                            </article>
                                        </div>
                                        {/* Order Details Block : End  */}


                                        {/* Money Back : Begin  */}
                                        <div className="money_back_sec checkout_moneyback forLargeDevice">
                                            <div className="row">
                                                <div className="col-sm-3">
                                                    <div id="ibu4nm" className="seal_div">
                                                        <img src={moneyBckSeal} className="moneybackLogo" alt="Money Back" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-9">
                                                    <h2><span className="gjs-selected">INCLUDES A 30 DAY 100%</span><br />MONEY BACK GUARANTEE</h2>
                                                    <p>Blue Forrest Beauty comes with a
                                                        100% 30 Day Money Back Guarantee. That means if you change your mind about this decision  all you need to do is
                                                        contact us, and we’ll refund your purchase.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Money Back : End  */}

                                    </div>
                                </div>
                                {/* Col End 1 */}

                                {/* Col Begin 2 */}
                                <div className="col-lg-4">
                                    {/* Sidebar Begin  */}
                                    <div className="sidebar">
                                        <div className="final_step_heading">
                                            <h1>FINAL STEP</h1>
                                            <h2>PAYMENT CONFIRMATION</h2>
                                        </div>
                                        <div className="sidebar_resize">
                                            <div className="prospect_form">
                                                <Formik
                                                    enableReinitialize = {true}
                                                    initialValues={{
                                                        card_type: '',
                                                        product_id:'',
                                                        cc_month: 0,
                                                        cc_year: '', 
                                                        ccnumber: '', 
                                                        cvv: '',
                                                        shipping_address: '',
                                                        shipping_city: '',
                                                        shipping_state: shipState || '',
                                                        shipping_zip: '',
                                                        shipping_country: shipCountry || 239,
                                                        affid: affid,
                                                        sid: sid,
                                                        s1: s1,
                                                        s2: s2,
                                                        s3: s3,
                                                        click_id: click_id,
                                                        notes: notes,
                                                        shipCheckbox:true
                                                    }}
                                                    validationSchema={Yup.object({
                                                        cc_year: Yup.string().when("submitButton", {
                                                            is: "paypal",
                                                            then: Yup.string().notRequired(),
                                                            otherwise: Yup.string().required("Select valid year"),
                                                        }),
                                                        cc_month: Yup.number().when("submitButton", {
                                                            is: "paypal",
                                                            then: Yup.number().notRequired(),
                                                            otherwise: Yup.number().moreThan(0,'Select valid month').required('Select month').when('cc_year', {
                                                                is: (cc_year) => (parseInt(moment().year()) === parseInt(cc_year)) ? true : false,
                                                                then: Yup.number().moreThan(parseInt(moment().format('MM'))-1, "Select valid month")
                                                            }),
                                                        }),
                                                        ccnumber: Yup.string().when("submitButton", {
                                                            is: "paypal",
                                                            then: Yup.string().notRequired(),
                                                            otherwise: Yup.string().matches(/^\d+$/, 'Only numbers are allowed')
                                                            .required('Card number is required')
                                                            .max(16, 'Credit card should not be grater than 16 digits')
                                                            .min(15, 'Credit card should not be less than 15 digits'),
                                                        }),
                                                        cvv: Yup.string().when("submitButton", {
                                                            is: "paypal",
                                                            then: Yup.string().notRequired(),
                                                            otherwise: Yup.string().matches(/^[0-9]{3,4}$/,
                                                                'Security code must be a 3 or 4 digit number')
                                                                .required('Security code is required'),
                                                        }),
                                                        shipCheckbox:Yup.string(),
                                                            shipping_address: Yup.string().when("shipCheckbox", {
                                                                is: (val)=> (val==='false') ? true:false,
                                                                then: Yup.string().required("Shipping address is required")
                                                            }),
                                                            shipping_city: Yup.string().when("shipCheckbox", {
                                                                is: (val)=> (val==='false') ? true:false,
                                                                then: Yup.string().required("Shipping city is required")
                                                            }),
                                                            shipping_state: Yup.string().when("shipCheckbox", {
                                                                is: (val)=> (val==='false') ? true:false,
                                                                then: Yup.string().required("Shipping state is required")
                                                            }),
                                                            shipping_zip: Yup.string().when("shipCheckbox", {
                                                                is: (val)=> (val==='false') ? true:false,
                                                                then: Yup.string().when('shipping_country', {
                                                                    is: '239',
                                                                    then: Yup.string()
                                                                      .required('Shipping zip code is required')
                                                                      .matches(/^\d{5}$/, 'Zip code must be exactly 5 digits number'),
                                                                    otherwise: Yup.string().when('shipping_country', {
                                                                      is: '41',
                                                                      then: Yup.string()
                                                                        .required('Shipping zip code is required')
                                                                        .matches(/^[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d$/, 'Zip code must contain only letters and numbers')
                                                                        .min(6, 'Zip code should contain at least 6 characters')
                                                                        .max(6, 'Zip code should contain at most 6 characters'),
                                                                    }),
                                                                }),
                                                            }),
                                                            shipping_country: Yup.string().when("shipCheckbox", {
                                                                is: (val)=> (val==='false') ? true:false,
                                                                then: Yup.string().required("Shipping country is required")
                                                            })
                                                    })}

                                                    onSubmit={(values, { setSubmitting }) => {
                                                        setSubmitting (true);
                                                        values.product_id=selectedProduct;
                                                        values.card_type = GetCardType('"' + values.ccnumber + '"');
                                                        //alert(JSON.stringify(values, null, 2));
                                                        if(values.shipping_address !==''){
                                                            values.billingSameAsShipping = "NO";
                                                        }else {
                                                            values.billingSameAsShipping = "YES";
                                                        }
                                                        if(values.submitButton === 'paypal'){
                                                            PayPalHandler(values);
                                                        } else {
                                                            confirmSubmit(values);
                                                        }
                                                    }}
                                                >
                                                {(formProps) => (
                                                    <Form>
                                                        <div className="payCardImgs">
                                                            <img className='' src={card_img} alt='' />
                                                        </div>
                                                        <div className="row form_row paymentMethod mb-4">
                                                            <div className="formDiv">
                                                                {/* shipping same as billing*/}
                                                                <div className='masterShipAddr'>
                                                                    <label className="checkboxLabel newCustomCheckbox mb-3">
                                                                        <Field type="checkbox" id="shippingCheckbox" name="shipCheckbox" checked={checkboxValue===true ? 'checked' :""} onClick={(event) => formProps.setFieldValue("shipCheckbox", event.target.checked)}  onChange={handleCheckboxChange} />
                                                                        <span className="cus_checkBoxButton"></span>
                                                                        <span className="newCustomCheckbox_label">Shipping is the same as billing</span>
                                                                    </label>
                                                                    {showDiv &&
                                                                    <div className="form-div-2 mb-3">
                                                                        <div className="form-holder">
                                                                            <label className="formLabel">Shipping Address <span className='text-danger'>*</span></label>
                                                                            <Field type="text" className="form-control" placeholder="Shipping Address" name="shipping_address"/>
                                                                            <CustomErrorMsg name="shipping_address" />
                                                                        </div>
                                                                        <div className="form-holder">
                                                                            <label className="formLabel">Shipping City <span className='text-danger'>*</span></label>
                                                                            <Field type="text" className="form-control" placeholder="Shipping City" name="shipping_city"/>
                                                                            <CustomErrorMsg name="shipping_city" />
                                                                        </div>
                                                                        <div className="form-holder">
                                                                            <label className="formLabel">Shipping Country <span className='text-danger'>*</span></label>
                                                                            <Field 
                                                                                as="select" 
                                                                                className="form-control" 
                                                                                name="shipping_country"
                                                                                onChange={(event) => {
                                                                                    formProps.handleChange(event);
                                                                                    handleCountryChange(event);
                                                                                }}
                                                                            >
                                                                                <option value="239" >United States</option>
                                                                                <option value="41" >Canada</option>
                                                                            </Field>
                                                                            <CustomErrorMsg name="shipping_country" />
                                                                        </div>
                                                                        <div className="form-holder">
                                                                            <label className="formLabel">Shipping State <span className='text-danger'>*</span></label>
                                                                            <Field 
                                                                                as="select" 
                                                                                className="form-control" 
                                                                                name="shipping_state"
                                                                            >
                                                                            <option value="" >Select State</option>
                                                                            {states.map((state) => (
                                                                                <option key={state.id} value={state.abbreviation}>
                                                                                    {state.state}
                                                                                </option>
                                                                            ))}
                                                                            </Field>
                                                                            <CustomErrorMsg name="shipping_state" />
                                                                        </div>
                                                                        <div className="form-holder">
                                                                            <label className="formLabel">Shipping Zip Code <span className='text-danger'>*</span></label>
                                                                            <Field type="text" maxLength="6" className="form-control" placeholder="Shipping Zip Code" name="shipping_zip"/>
                                                                            <CustomErrorMsg name="shipping_zip" />
                                                                        </div>
                                                                    </div>
                                                                    }
                                                                </div>
                                                                {/* shipping same as billing*/}
                                                                <div className={`payPal radioSec  cutom__radio-section ${selectedPayment === 'paypal' ? 'expanded' : ''}`}>
                                                                    <input
                                                                        type="radio"
                                                                        name="contactPreference"
                                                                        value="paypal"
                                                                        className="rb-paypal cutom__radio-input"
                                                                        id="rb-paypal"
                                                                        onChange={(event) => handlePaymentSelection(event.target.value)}
                                                                        checked={selectedPayment === 'paypal'}
                                                                    />
                                                                    <label htmlFor="rb-paypal" className="cutom__radio-button"></label>
                                                                    <label className="label label-title" htmlFor="rb-paypal"><img src={paypal} className="paypallogo img-fluid" /></label>
                                                                    <div className="label paypaldiv" htmlFor="paypaldiv">
                                                                        <img src={paypalBtn} className="paypalBtn img-fluid"/>
                                                                    </div>
                                                                </div>
                                                                <div className={`creditCard radioSec  cutom__radio-section ${selectedPayment === 'creditCard' ? 'expanded' : ''}`}>
                                                                    <input
                                                                        type="radio"
                                                                        name="contactPreference"
                                                                        value="creditCard"
                                                                        className="rb-credit cutom__radio-input"
                                                                        id="rb-credit"
                                                                        onChange={(event) => handlePaymentSelection(event.target.value)}
                                                                        checked={selectedPayment === 'creditCard'}
                                                                    />
                                                                    <label htmlFor="rb-credit" className="cutom__radio-button"></label>
                                                                    <label className="label label-title" htmlFor="rb-credit">Credit Card or Debit</label>
                                                                    <div className="label creditdiv" htmlFor="creditdiv">
                                                                        <div className="formRow row">
                                                                            <p className="label-subtitle my-2">Visa, Mastercard, American Express, Discover</p>                                                                  
                                                                            <div className="formCol col-12">
                                                                                <label>Card Number:</label>
                                                                                <Field type="hidden" className="form-control" id="card_type" name="card_type" value={cardType}/>
                                                                                <Field type="hidden" className="form-control" name="affid" value={affid} />
                                                                                <Field type="hidden" className="form-control" name="sid" value={sid} />
                                                                                <Field type="hidden" className="form-control" name="s1" value={s1} />
                                                                                <Field type="hidden" className="form-control" name="s2" value={s2} />
                                                                                <Field type="hidden" className="form-control" name="s3" value={s3} />
                                                                                <Field type="hidden" className="form-control" name="click_id" value={click_id} />
                                                                                <Field type="hidden" className="form-control" name="notes" value={notes} />
                                                                                <Field type="text" maxLength="16" className="form-control" placeholder="Card Number" name="ccnumber" onKeyUp={(event)=>setCreditCardType(event.target.value)}/>
                                                                                <CustomErrorMsg name="ccnumber" />                                                                            
                                                                            </div>
                                                                            <div className="formCol col-12">
                                                                                <label>CVV:</label>
                                                                                <Field type="text"  maxLength="4" className="mm-textField form-control" placeholder="CVV" name="cvv" />
                                                                                <CustomErrorMsg name="cvv" />                                                                           
                                                                            </div>
                                                                            <div className="col-12">
                                                                                <div className="row cardTime-row">
                                                                                    <div className="formCol col-12 col-md-6 col-lg-12 col-xl-6">
                                                                                        <label>Select Month:</label>
                                                                                        <Field as="select" className="form-control mm-selectList" name="cc_month"  >
                                                                                            <option value="0">Select Month</option>
                                                                                            <option value="01">January</option>
                                                                                            <option value="02">February</option>
                                                                                            <option value="03">March</option>
                                                                                            <option value="04">April</option>
                                                                                            <option value="05">May</option>
                                                                                            <option value="06">June</option>
                                                                                            <option value="07">July</option>
                                                                                            <option value="08">August</option>
                                                                                            <option value="09">September</option>
                                                                                            <option value="10">October</option>
                                                                                            <option value="11">November</option>
                                                                                            <option value="12">December</option>
                                                                                        </Field>
                                                                                        <CustomErrorMsg name="cc_month" />
                                                                                    </div>
                                                                                    <div className="formCol col-12 col-md-6 col-lg-12 col-xl-6">
                                                                                        <label>Select Year:</label>
                                                                                        <Field as="select" className="form-control" name="cc_year" >
                                                                                            <option value="">Select Year</option>
                                                                                            {years.map(year => (
                                                                                                <option key={year} value={year}>
                                                                                                    {year}
                                                                                                </option>
                                                                                            ))}
                                                                                        </Field>
                                                                                        <CustomErrorMsg name="cc_year" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            
                                                                        </div>
                                                                    </div>
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='row form_row'>
                                                            <div className='col-md-12 form_col'>
                                                                <button 
                                                                    type="submit" 
                                                                    style={{ background: 'transparent' }} 
                                                                    className='pay-btn text-center' >
                                                                    <img src={PaymentButton} alt="" className="img-fluid" 
                                                                    onClick={(event) => formProps.setFieldValue("submitButton", selectedPayment)}
                                                                />
                                                                </button>
                                                            </div>    
                                                        </div>
                                                    </Form>
                                                )}
                                                </Formik>
                                                <div className="form-group text-center">
                                                    <img className='' alt='' src={trustBadge} />
                                                    <p>Secure 256-bit encrypted connection</p>							  
                                                </div>
                                                {checkoutFunnelData && checkoutFunnelData.length > 0 && (
                                                    <>
                                                        <div className={checkoutFunnelData[0].membership_add_on === 1 ? 'yellowbox mt-4' : 'd-none'}>
                                                            <i>
                                                                <img className="pulse" src={Arrow2} alt="" />
                                                            </i>
                                                            <div className="check_area">
                                                                <input
                                                                    id="checkD"
                                                                    className="vip_shipping_desk"
                                                                    onChange={() => { handleSPAddon() }}
                                                                    defaultChecked={SMAddonToggle}
                                                                    type="checkbox"
                                                                />
                                                                <label htmlFor="checkD">YES, I want FREE Shipping With Sapphire Membership!</label>
                                                            </div>
                                                        </div>

                                                        <div className={checkoutFunnelData[0].membership_add_on == 1 ? SMToggle ? 'top-terms open' : 'top-terms' : 'd-none'}>
                                                            <h1 className="top-termsHdng" onClick={() => { setSMToggle(!SMToggle) }}>Sapphire Membership Terms</h1>
                                                            <div className="top-trends-desc">
                                                                <p>BIG SAVINGS are ahead! Sapphire Membership comes loaded with benefits. At Wholesalehealthclub we pride ourselves on offering customers the best deals on the products they love. Your Sapphire Membership takes that one step further.</p>
                                                                <p>As a Sapphire Membership member we’ll send you a unique discount code each month to your inbox. Be sure to use this code at checkout to take advantage of the following benefits with your Sapphire Membership Today!:</p>
                                                                <p>-FREE shipping on all orders!</p>
                                                                <p>-Up to 80% off all of the great products you’ll find on wholesalehealthclub.com</p>
                                                                <p>-No hassle, no questions asked replacement on any items.</p>
                                                                <p>-Priority Processing – Have your orders placed in front of the line!</p>
                                                                <p>-Get it all FREE for the first 14 days!</p>
                                                                <p>Some Notes About Your Membership:</p>
                                                                <p>Remember – while your 2 weeks of Wholesalehealthclub Sapphire Membership is free of charge, you will be billed $19.83/ month to retain membership after your first 2 weeks. If you wish to cancel your Sapphire Membership at any time, simply contact <br />our support using the below information: Support Email: <a href="mailto:support@wholesalehealthclub.com"> support@wholesalehealthclub.com </a></p>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                                {/* Term Box End  */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* Sidebar End  */}
                                    {/* Testimonial Block : Begin  */}
                                    <div className="testSmBlock">
                                        <img src={testimonial1} alt="img1" className="" />
                                        <div className="testSmBlock_quote">
                                            <i>
                                            This works! After just a few weeks my neck looks years younger and I've
                                            only just begun using this!
                                            </i>
                                        </div>
                                        <div className="testSmBlock_name">
                                            <p> <b>Susan C, 63, Delaware </b></p>
                                        </div>
                                    </div>
                                    {/* Testimonial Block : End  */}
                                    {/* Testimonial Block : Begin  */}
                                    <div className="testSmBlock">
                                        <img src={testimonial2} alt="img1" className="" />
                                        <div className="testSmBlock_quote">
                                            <i>
                                            I am a 67-year-old who found myself staying indoors and avoiding going out. Now, I call myself a "social butterfly" and am busier than ever in my community...
                                            </i>
                                        </div>
                                        <div className="testSmBlock_name">
                                            <p> <b>Jackie Z …the “social butterfly” at age 67.</b></p>
                                        </div>
                                    </div>
                                    {/* Testimonial Block : End  */}
                                    {/* Money Back : Begin  */}
                                    <div className="money_back_sec checkout_moneyback forSmallDevice">
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <div id="ibu4nm" className="seal_div">
                                                    <img src={moneyBckSeal} className="moneybackLogo" alt="Money Back" />
                                                </div>
                                            </div>
                                            <div className="col-sm-9">
                                                <h2><span className="gjs-selected">INCLUDES A 30 DAY 100%</span><br />MONEY BACK GUARANTEE</h2>
                                                <p>Blue Forrest Beauty comes with a
                                                    100% 30 Day Money Back Guarantee. That means if you change your mind about this decision  all you need to do is
                                                    contact us, and we’ll refund your purchase.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Money Back : End  */}
                                </div>
                                {/* Col End 2 */}
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
            <Copyright/>
         </>
    )
}


export default Skincare6pCheckout;