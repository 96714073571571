import React, { useState, useEffect } from "react";
import Accordion from 'react-bootstrap/Accordion';
import { extendWith, omit } from 'lodash';
import Loader from '../Loader';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import useStore from '../../store';
import { Formik, Field, Form, useField } from 'formik';
import * as Yup from 'yup';
import CustomErrorMsg from "./CustomErrorMsg";
import moment from "moment";
import {BASE_URL,stateList,GetCardType} from '../Config';

import sapphireMembership from '../../assests/frontend/assets/images/platinum-membership.jpg';
import goldTier from '../../assests/frontend/assets/images/rewards-membership-gold-tier.png';
import platinumTier from '../../assests/frontend/assets/images/rewards-membership-platinum-tier.png';
import payLogos from '../../assests/frontend/assets/images/cclogos.gif';
import securityLogos from '../../assests/frontend/assets/images/secure.jpg';
//import moneyBack from '../../assests/frontend/assets/images/icon-mb1.png';
import LoaderBeta from "../LoaderBeta";
import moneyBack from '../../assests/frontend/assets/images/30-day-money-back.png';
import paypal from '../../assests/frontend/assets/images/paypal.png';

function CheckoutPageBeta() {

    const [showDiv, setShowDiv] = useState(false);
    const [productId, setProductId] = useState("");
    const [orderType, setOrderType] = useState("");
    const afterLogin = useStore((state) => state.afterLogin);
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const location = useLocation();
    const [inputValue, setInputValue] = useState('');
    const [activeClass, setActiveClass] = useState('');
    const [billingSameAsShipping, setBillingSameAsShipping] = useState('NO');
    const [checkboxValue, setcheckboxValue] = useState(true);
    const [cardType, setCardType] = useState("");
    const [cc_number, setcc_number] = useState("");
    const [selectedPayment, setSelectedPayment] = useState('paypal');
    const handlePaymentSelection = (value) => {
        setSelectedPayment(value);
    };
    const [isUpdateButtonClicked, setIsUpdateButtonClicked] = useState(false);
    const [shipStates, setShipStates] = useState([]);

    let email = localStorage.getItem("V1email");
    let refCode = new URLSearchParams(location.search).get('ref');
    let affid = new URLSearchParams(location.search).get('affid');
    let sid = new URLSearchParams(location.search).get('sid');
    let s1 = new URLSearchParams(location.search).get('s1');
    let s2 = new URLSearchParams(location.search).get('s2');
    let s3 = new URLSearchParams(location.search).get('s3');
    let click_id = new URLSearchParams(location.search).get('click_id');
    let notes = new URLSearchParams(location.search).get('notes');
    let localURL = window.location.origin.includes('localhost') ? 'http://localhost:3000/' : window.location.origin+'/';

    const handleCheckboxChange = (event) => {
        setcheckboxValue(event.target.checked);
        setShowDiv(!event.target.checked);
    };

    //State List ;
    const options = stateList;
    const [states, setStates] = useState([]);

    const handleShipCountryChange = (country_id) => {
        //const country_id = event.target.value;
        axios.get(`api/get-state/${country_id}`).then(res => {
            if(res.data.status === true) {
                setShipStates(res.data.data);
            }
        })
    };
    
    const handleCountryChange = (event) => {
        const country_id = event.target.value;
        axios.get(`api/get-state/${country_id}`).then(res => {
            if(res.data.status === true) {
                setStates(res.data.data);
            }
        })
    };

    function setCreditCardType(CCNumber){
        if(CCNumber.length>=15){
            //console.log(GetCardType(CCNumber));
            setcc_number(CCNumber);
            setCardType(GetCardType(CCNumber));
            document.getElementById('card_type').value = GetCardType(CCNumber);
        }
    }

    const handleCountryChange2 = (country_id) => {
        axios.get(`api/get-state/${country_id}`).then(res => {
            if(res.data.status === true) {
                setStates(res.data.data);
            }
        })
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        setProductId('57');
        setOrderType('membership');
        handleCountryChange2('239');
        handleShipCountryChange('239');
    }, []);

    const currentYear = new Date().getFullYear();
    const years = Array.from(new Array(10), (val, index) => currentYear + index);

    const updateSubmit = (formdata) => {
        if (!isUpdateButtonClicked) {
            setIsUpdateButtonClicked(true);
            setLoaded(true);
            /* diable enter and space key */
            const handleKeyPress = (event) => {
                if (event.key === ' ' || event.key === 'Enter') {
                  event.preventDefault();
                }
            };
            document.addEventListener('keydown', handleKeyPress);
            /* diable enter and space key */
            axios.post(`api/place_shop_order`, formdata).then(res => {
                if (res.data.response_code === '100') {
                    localStorage.setItem('authToken', res.data.member_token);
                    localStorage.setItem('permission', res.data.user_info.account_permission);
                    localStorage.setItem('userId', res.data.user_info.user_id);
                    localStorage.setItem('userEmal', res.data.user_info.email);
                    localStorage.setItem('order_id', res.data.order_id);

                    axios.get(`api/check_user_membership/${formdata.email}`).then(result => {
                        setIsUpdateButtonClicked(false);
                        let is_membership = 0;
                        if(parseInt(result.data) === 1){
                            is_membership = 1;
                        } else {
                            console.log('else');
                            is_membership = 0;
                        }
                        afterLogin(res.data.user_info.account_permission, res.data.user_info.user_type, res.data.user_info.user_id, res.data.user_info, is_membership);
                        setLoaded(false);
                        navigate("/confirmation");
                    }); 
                }else
                {
                    setIsUpdateButtonClicked(false);
                    setLoaded(false);
                    Swal.fire({
                        title: 'Warning',
                        html: res.data.message,
                        icon: 'warning'
                    })

                }
            }).catch(err => {
                //console.log(err.response);
                setLoaded(false);
                Swal.fire({
                    title: 'Warning',
                    html: err.response.data.message,
                    icon: 'warning'
                })
            });
        }
    }

    //PayPalSubmit Handler
    function PayPalHandler(values){
        let product_id = values.product_id;
        let firstName = values.first_name;
        let lastName = values.last_name;
        let address = values.address;
        let zip = values.zip;
        let state = values.state;
        let city = values.city;
        let phone = values.phone;
        let email = values.email;
        let country = values.country;
        let prospectId = '';
        let refcode = values.refCode;
        let alt_pay_url = localURL+'confirmation';
        let combine_product = 'null';
        let funnel = "discount";

        /* affiliate params*/
        let affid = new URLSearchParams(location.search).get('affid');
        let sid = new URLSearchParams(location.search).get('sid');
        let s1 = new URLSearchParams(location.search).get('s1');
        let s2 = new URLSearchParams(location.search).get('s2');
        let s3 = new URLSearchParams(location.search).get('s3');
        let click_id = new URLSearchParams(location.search).get('click_id');
        let notes = new URLSearchParams(location.search).get('notes');

        localStorage.setItem('firstName', values.first_name);
        localStorage.setItem('lastName', values.last_name);
        localStorage.setItem('shippingAddress1', values.address);
        localStorage.setItem('shippingZip', values.zip);
        localStorage.setItem('shippingState', values.state);
        localStorage.setItem('shippingCity', values.city);
        localStorage.setItem('phone', values.phone);
        localStorage.setItem('email', values.email);
        localStorage.setItem('country', (values.country === '239') ? 'US' : 'CA');
        localStorage.setItem('method','paypal');
        localStorage.setItem('c_s','membership-checkout');
        localStorage.setItem('referral_code',refcode);
        localStorage.setItem('funnel',funnel);

        localStorage.setItem('affid',affid);
        localStorage.setItem('sid',sid);
        localStorage.setItem('s1',s1);
        localStorage.setItem('s2',s2);
        localStorage.setItem('s3',s3);
        localStorage.setItem('click_id',click_id);
            
        window.location.href=BASE_URL+'whc-custom/public/api/paypal?product_id='+product_id+
        '&refcode='+refcode+
        '&firstName='+firstName+
        '&lastName='+lastName+
        '&address='+address+
        '&zip='+zip+
        '&state='+state+
        '&city='+city+
        '&phone='+phone+
        '&email='+email+
        '&country='+country+
        '&prospectId='+prospectId+
        '&alt_pay_url='+alt_pay_url+
        '&combine_product='+combine_product+
        '&funnel='+funnel+
        '&affid='+affid+
        '&sid='+sid+
        '&s1='+s1+
        '&s2='+s2+
        '&s3='+s3+
        '&click_id='+click_id+
        '&notes='+notes;

    }

    return (
        <>
            {loaded ? <LoaderBeta /> : null}
            {/* Checkout Page Begin  */}
            <div className='checkoutPage'>
                <div className='checkout-sec-1'>
                    <div className='container'>
                        <div className='checkContainer'>
                            {/* Row Begin  */}
                            <div className='row mb-4'>
                                {/* Col Begin  */}
                                <div className='col-md-7 col-lg-8 mb-4 mb-md-0'>
                                    <div className='checkAcc'>
                                        <Accordion defaultActiveKey="0">
                                            <Accordion.Item eventKey="0">
                                                {/* <Accordion.Header><span className='chk-box'></span>Silver– $10.00</Accordion.Header> */}
                                                <Accordion.Body>
                                                    <div className='tierImg'>
                                                        <img src={sapphireMembership} className="img-fluid" />
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                </div>
                                {/* Col End  */}
                                {/* Col Begin  */}
                                <div className='col-md-5 col-lg-4'>
                                    <div className='checkoutFormCont'>
                                        <Formik
                                            enableReinitialize={true}
                                            initialValues={{
                                                first_name: '',
                                                last_name: '',
                                                phone: '',
                                                email: email,
                                                address: '',
                                                city: '',
                                                state: '',
                                                zip: '',
                                                country: '239',
                                                creditCardType: '',
                                                cc_month: 0,
                                                cc_year: '',
                                                creditCardNumber: '',
                                                cvv: '',
                                                acceptTerms: false,
                                                shipping_address: '',
                                                shipping_city: '',
                                                shipping_state: '',
                                                shipping_zip: '',
                                                shipping_country: '239',
                                                refCode: refCode,
                                                affid: affid,
                                                sid: sid,
                                                s1: s1,
                                                s2: s2,
                                                s3: s3,
                                                click_id: click_id,
                                                notes: notes
                                            }}
                                            validationSchema={Yup.object({
                                                first_name: Yup.string().when("submitButton", {
                                                    is: "paypal",
                                                    then: Yup.string().notRequired(),
                                                    otherwise: Yup.string().required('First name is required'),
                                                }),
                                                last_name: Yup.string().when("submitButton", {
                                                    is: "paypal",
                                                    then: Yup.string().notRequired(),
                                                    otherwise: Yup.string().required('Last name is required'),
                                                }),
                                                email: Yup.string().when("submitButton", {
                                                    is: "paypal",
                                                    then: Yup.string().notRequired(),
                                                    otherwise: Yup.string().email("Email is required").max(255).required("Please enter a valid email"),
                                                }),
                                                phone: Yup.string().when("submitButton", {
                                                    is: "paypal",
                                                    then: Yup.string().notRequired(),
                                                    otherwise: Yup.string().required('Phone no is required')
                                                        .matches(/^[0-9]+$/, "Please enter only number!")
                                                        .min(10, 'Please enter a valid contact number!')
                                                        .max(10, 'Please enter a valid contact number!'),
                                                }),
                                                state: Yup.string().when("submitButton", {
                                                    is: "paypal",
                                                    then: Yup.string().notRequired(),
                                                    otherwise: Yup.string().required('Please select state'),
                                                }),
                                                cc_year: Yup.string().when("submitButton", {
                                                    is: "paypal",
                                                    then: Yup.string().notRequired(),
                                                    otherwise: Yup.string().required("Select valid year"),
                                                }),
                                                cc_month: Yup.number().when("submitButton", {
                                                    is: "paypal",
                                                    then: Yup.number().notRequired(),
                                                    otherwise: Yup.number().moreThan(0,'Select valid month').required('Select month').when('cc_year', {
                                                        is: (cc_year) => (parseInt(moment().year()) === parseInt(cc_year)) ? true : false,
                                                        then: Yup.number().moreThan(parseInt(moment().format('MM'))-1, "Select valid month")
                                                    }),
                                                }),
                                                address: Yup.string().when("submitButton", {
                                                    is: "paypal",
                                                    then: Yup.string().notRequired(),
                                                    otherwise: Yup.string().required('Address is required'),
                                                }),
                                                city: Yup.string().when("submitButton", {
                                                    is: "paypal",
                                                    then: Yup.string().notRequired(),
                                                    otherwise: Yup.string().required('City is required'),
                                                }),
                                                zip: Yup.string().when("submitButton", {
                                                    is: "paypal",
                                                    then: Yup.string().notRequired(),
                                                    otherwise: Yup.string().when('country', {
                                                        is: '239',
                                                        then: Yup.string()
                                                            .required('Zip code is required')
                                                            .matches(/^\d{5}$/, 'Zip code must be exactly 5 digits number'),
                                                        otherwise: Yup.string().when('country', {
                                                            is: '41',
                                                            then: Yup.string()
                                                            .required('Zip code is required')
                                                            .matches(/^[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d$/, 'Zip code must contain only letters and numbers')
                                                            .min(6, 'Zip code should contain at least 6 characters')
                                                            .max(6, 'Zip code should contain at most 6 characters'),
                                                        }),
                                                    }),
                                                }),
                                                acceptTerms: Yup.boolean().oneOf([true], "You must accept the terms and conditions"),
                                                creditCardNumber: Yup.string().when("submitButton", {
                                                    is: "paypal",
                                                    then: Yup.string().notRequired(),
                                                    otherwise: Yup.string().matches(/^\d+$/, 'Only numbers are allowed')
                                                    .required('Card number is required')
                                                    .max(16, 'Credit card should not be grater than 16 digits')
                                                    .min(15, 'Credit card should not be less than 15 digits'),
                                                }),
                                                cvv: Yup.string().when("submitButton", {
                                                    is: "paypal",
                                                    then: Yup.string().notRequired(),
                                                    otherwise: Yup.string().matches(/^[0-9]{3,4}$/,
                                                        'Security code must be a 3 or 4 digit number')
                                                        .required('Security code is required'),
                                                }),
                                                shipCheckbox:Yup.string(),
                                                shipping_address: Yup.string().when("shipCheckbox", {
                                                    is: (val)=> (val==='false') ? true:false,
                                                    then: Yup.string().required("Shipping address is required")
                                                }),
                                                shipping_city: Yup.string().when("shipCheckbox", {
                                                    is: (val)=> (val==='false') ? true:false,
                                                    then: Yup.string().required("Shipping city is required")
                                                }),
                                                shipping_state: Yup.string().when("shipCheckbox", {
                                                    is: (val)=> (val==='false') ? true:false,
                                                    then: Yup.string().required("Shipping state is required")
                                                }),
                                                shipping_zip: Yup.string().when("shipCheckbox", {
                                                    is: (val)=> (val==='false') ? true:false,
                                                    then: Yup.string().when('shipping_country', {
                                                        is: "239",
                                                        then: Yup.string()
                                                            .required('Shipping zip code is required')
                                                            .matches(/^\d{5}$/, 'Zip code must be exactly 5 digits number'),
                                                        otherwise: Yup.string().when('shipping_country', {
                                                            is: "41",
                                                            then: Yup.string()
                                                            .required('Shipping zip code is required')
                                                            .matches(/^[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d$/, 'Zip code must contain only letters and numbers')
                                                            .min(6, 'Zip code should contain at least 6 characters')
                                                            .max(6, 'Zip code should contain at most 6 characters'),
                                                        }),
                                                    }),
                                                }),
                                                shipping_country: Yup.string().when("shipCheckbox", {
                                                    is: (val)=> (val==='false') ? true:false,
                                                    then: Yup.string().required("Shipping country is required")
                                                })
                                            })}

                                            onSubmit={(values, { setSubmitting }) => {
                                                setSubmitting(true);
                                                //alert(JSON.stringify(values, null, 2));
                                                values.product_id = '57';
                                                values.order_type = 'membership';
                                                values.creditCardType = GetCardType('"' + values.creditCardNumber + '"');
                                                if (values.shipping_address !== '') {
                                                    values.billingSameAsShipping = "NO";
                                                } else {
                                                    values.billingSameAsShipping = "YES";
                                                }
                                                if(values.country === '239'){
                                                    values.country = 'US';
                                                } else {
                                                    values.country = 'CA';
                                                }
                                                if(values.shipping_country === '239'){
                                                    values.shipping_country = 'US';
                                                } else {
                                                    values.shipping_country = 'CA';
                                                }

                                                if(values.submitButton === 'paypal'){
                                                    PayPalHandler(values);
                                                } else {
                                                    //console.log(values);
                                                    updateSubmit(values);
                                                }
                                            }}
                                        >
                                            {(formProps) => (
                                            <Form>
                                                <div className="formDiv">
                                                    {/* <h2 className="formTitle">Billing Details</h2> */}
                                                    <div className="formRow row paymentMethod mc-payment">
                                                        <div className="formDiv">
                                                            <h2 className="formTitle">Payment Method</h2>
                                                            <div className={`payPal radioSec ${selectedPayment === 'paypal' ? 'expanded' : ''}`}>
                                                                <Field
                                                                    type="radio"
                                                                    name="contactPreference"
                                                                    value="paypal"
                                                                    className="rb-paypal"
                                                                    id="rb-paypal"
                                                                    onClick={(event) => handlePaymentSelection(event.target.value)}
                                                                    checked={selectedPayment === 'paypal'}
                                                                />
                                                                <label className="label label-title" htmlFor="rb-paypal"><img src={paypal} className="paypallogo img-fluid" /></label>
                                                            </div>
                                                            <div className={`creditCard radioSec ${selectedPayment === 'creditCard' ? 'expanded' : ''}`}>
                                                                <Field
                                                                    type="radio"
                                                                    name="contactPreference"
                                                                    value="creditCard"
                                                                    className="rb-credit"
                                                                    id="rb-credit"
                                                                    onClick={(event) => handlePaymentSelection(event.target.value)}
                                                                    checked={selectedPayment === 'creditCard'}
                                                                />
                                                                <label className="label label-title" htmlFor="rb-credit">Credit Card or Debit</label>
                                                                <div className="label creditdiv" htmlFor="creditdiv">
                                                                    <div className="formRow row">
                                                                        <div className="col-md-12 formDiv-col mc-card">
                                                                            <div className="formDiv">
                                                                                <h2 className="formTitle">Account Information</h2>
                                                                                <div className="formRow row">
                                                                                    <div className="formCol col-12">
                                                                                        <Field type="hidden" className="form-control" id="card_type" name="creditCardType" value={cardType}/>
                                                                                        <Field type="hidden" className="form-control" name="affid" value={affid} />
                                                                                        <Field type="hidden" className="form-control" name="sid" value={sid} />
                                                                                        <Field type="hidden" className="form-control" name="s1" value={s1} />
                                                                                        <Field type="hidden" className="form-control" name="s2" value={s2} />
                                                                                        <Field type="hidden" className="form-control" name="s2" value={s3} />
                                                                                        <Field type="hidden" className="form-control" name="s2" value={click_id} />
                                                                                        <Field type="hidden" className="form-control" name="s2" value={notes} />
                                                                                        <Field type="text" className="form-control" placeholder="First Name" name="first_name" />
                                                                                        <CustomErrorMsg name="first_name" />
                                                                                    </div>
                                                                                    <div className="formCol col-12">
                                                                                        <Field type="text" className="form-control" placeholder="Last Name" name="last_name" />
                                                                                        <CustomErrorMsg name="last_name" />
                                                                                    </div>
                                                                                    <div className="formCol col-12">
                                                                                        <Field type="email" className="form-control" placeholder="Email Address" name="email" />
                                                                                        <CustomErrorMsg name="email" />
                                                                                    </div>
                                                                                    <div className="formCol col-12">
                                                                                        <Field type="tel" maxLength="10" className="form-control" placeholder="Phone Number" name="phone" />
                                                                                        <CustomErrorMsg name="phone" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-12 formDiv-col mc-card">
                                                                            <div className="formDiv">
                                                                                <h2 className="formTitle">Billing Address</h2>
                                                                                <div className="formRow row">
                                                                                    <div className="formCol col-md-12">
                                                                                        <Field type="text" className="form-control" placeholder="Billing Address" name="address" />
                                                                                        <CustomErrorMsg name="address" />
                                                                                    </div>
                                                                                    <div className="formCol col-12">
                                                                                        <Field type="text" className="form-control" placeholder="Billing City" name="city" />
                                                                                        <CustomErrorMsg name="city" />
                                                                                    </div>
                                                                                    <div className="formCol col-12">
                                                                                        <Field 
                                                                                            as="select" 
                                                                                            className="form-control" 
                                                                                            name="country"
                                                                                            onChange={(event) => {
                                                                                                formProps.handleChange(event);
                                                                                                handleCountryChange(event); // Call the custom function here if needed
                                                                                            }}
                                                                                        >
                                                                                            <option value="239" selected="selected">United States</option>
                                                                                            <option value="41" >Canada</option>
                                                                                        </Field>
                                                                                        <CustomErrorMsg name="country" />
                                                                                    </div>
                                                                                    <div className="formCol col-12">
                                                                                        <Field type="text" maxLength="6" className="form-control" placeholder="Billing Zip Code" name="zip" />
                                                                                        <CustomErrorMsg name="zip" />
                                                                                    </div>
                                                                                    <div className="formCol col-12">
                                                                                        <Field as="select" className="form-control" name="state">
                                                                                            <option value="" >Select state</option>
                                                                                            {states.map((state) => (
                                                                                                <option key={state.id} value={state.abbreviation}>
                                                                                                    {state.state}
                                                                                                </option>
                                                                                            ))}
                                                                                        </Field>
                                                                                        <CustomErrorMsg name="state" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-12 formDiv-col mc-card">
                                                                            <div className="formDiv">
                                                                                <h2 className="formTitle">Shipping Address</h2>
                                                                                <label className="shippingChec" htmlFor='shippingCheckbox'>
                                                                                    <Field type="checkbox" id="shippingCheckbox" name="shipCheckbox" checked={checkboxValue===true ? 'checked' :""} onClick={(event) => formProps.setFieldValue("shipCheckbox", event.target.checked)}  onChange={handleCheckboxChange} />Shipping is the same as billing
                                                                                </label>
                                                                                {showDiv &&
                                                                                    <div className="formRow row">
                                                                                        <div className="formCol col-md-12">
                                                                                            <Field type="text" className="form-control" placeholder="Shipping Address" name="shipping_address" />
                                                                                            <CustomErrorMsg name="shipping_address" />
                                                                                        </div>
                                                                                        <div className="formCol col-12">
                                                                                            <Field type="text" className="form-control" placeholder="Shipping City" name="shipping_city" />
                                                                                            <CustomErrorMsg name="shipping_city" />
                                                                                        </div>
                                                                                        <div className="formCol col-12">
                                                                                            <Field 
                                                                                                as="select" 
                                                                                                className="form-control" 
                                                                                                name="shipping_country"
                                                                                                onChange={(event) => {
                                                                                                    formProps.handleChange(event);
                                                                                                    handleShipCountryChange(event.target.value);
                                                                                                }}
                                                                                            >
                                                                                                <option value="239" selected="selected">United States</option>
                                                                                                <option value="41" >Canada</option>
                                                                                            </Field>
                                                                                            <CustomErrorMsg name="shipping_country" />
                                                                                        </div>
                                                                                        <div className="formCol col-12">
                                                                                            <Field type="text" maxLength="6" className="form-control" placeholder="Shipping Zip Code" name="shipping_zip" />
                                                                                            <CustomErrorMsg name="shipping_zip" />
                                                                                        </div>
                                                                                        <div className="formCol col-12">
                                                                                            <Field as="select" className="form-control" name="shipping_state">
                                                                                                <option value="" >Select Shipping State</option>
                                                                                                {shipStates.map((state) => (
                                                                                                    <option key={state.id} value={state.abbreviation}>
                                                                                                        {state.state}
                                                                                                    </option>
                                                                                                ))}
                                                                                            </Field>
                                                                                            <CustomErrorMsg name="shipping_state" />
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <span className="label-subtitle mb-3">Visa, Mastercard, American Express, Discover</span>
                                                                        <div className="formCol col-12">
                                                                            <Field type="text" maxLength={16} className="form-control" placeholder="Card Number" name="creditCardNumber" onKeyUp={(event)=>setCreditCardType(event.target.value)}/>
                                                                            <CustomErrorMsg name="creditCardNumber" />
                                                                        </div>
                                                                        <div className="formCol col-12">
                                                                            <Field type="text" maxLength={4} className="form-control" placeholder="CVV" name="cvv" />
                                                                            <CustomErrorMsg name="cvv" />
                                                                        </div>
                                                                        <div className="col-12">
                                                                            <div className="row cardTime-row">
                                                                                <div className="formCol col-12">
                                                                                    <Field as="select" className="form-control" name="cc_month"  >
                                                                                        <option value="0">Select</option>
                                                                                        <option value="01">January</option>
                                                                                        <option value="02">February</option>
                                                                                        <option value="03">March</option>
                                                                                        <option value="04">April</option>
                                                                                        <option value="05">May</option>
                                                                                        <option value="06">June</option>
                                                                                        <option value="07">July</option>
                                                                                        <option value="08">August</option>
                                                                                        <option value="09">September</option>
                                                                                        <option value="10">October</option>
                                                                                        <option value="11">November</option>
                                                                                        <option value="12">December</option>
                                                                                    </Field>
                                                                                    <CustomErrorMsg name="cc_month" />
                                                                                </div>
                                                                                <div className="formCol col-12">
                                                                                    <Field as="select" className="form-control" name="cc_year" >
                                                                                        <option value="">Select</option>
                                                                                        {years.map(year => (
                                                                                            <option key={year} value={year}>
                                                                                                {year}
                                                                                            </option>
                                                                                        ))}
                                                                                    </Field>
                                                                                    <CustomErrorMsg name="cc_year" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='checkoutFormDiv'>
                                                    <h3 className='checkoutFormTitle'>Referral Code</h3>
                                                    <div className='row'>
                                                        <div className='frm_grp col-md-12'>
                                                            <Field type="text" className="form-control" name="refCode" value={refCode} />
                                                        </div>
                                                        <div className='frm_grp col-md-12'>
                                                            <div className='checkBox-div'>
                                                                <Field name="acceptTerms" type="checkbox" id="termsCheck" />
                                                                <label htmlFor="scales">
                                                                    By checking this box you confirm that you acknowledge the terms presented in the <Link to='#' onClick={(event) => {
                                                                        event.preventDefault();
                                                                        window.open('/term-and-condition', '_blank', 'toolbar=no,scrollbars=no,resizable=no,width=400,height=400');
                                                                    }}>Terms And Conditions</Link> and <Link to="#" onClick={(event) => {
                                                                        event.preventDefault();
                                                                        window.open('/privacy-policy', '_blank', 'toolbar=no,scrollbars=no,resizable=no,width=400,height=400');
                                                                    }}>Privacy Policy</Link>. Today’s charge is $21.00 By clicking ‘Submit Order’ you agree that you are signing up for the membership (starting today). Your card will be automatically charged $21.00 every month thereafter for a period of 30 days from date of purchase. You can cancel anytime by calling <a class="tel_link" href="tel:(480) 573-0829">(480) 573-0829</a> or by emailing <a href="mailto:support@wholesalehealthclub.com">support@wholesalehealthclub.com</a>.
                                                                </label>
                                                                <CustomErrorMsg name="acceptTerms" />
                                                            </div>
                                                        </div>
                                                        <div className='frm_grp col-md-12'>
                                                            <button 
                                                                type="submit" 
                                                                className="btn submitBtn" 
                                                                name="submitButton" 
                                                                onClick={(event) => formProps.setFieldValue("submitButton", selectedPayment)}
                                                            >Submit Order</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>
                                            )}
                                        </Formik>
                                        <ul className='flinks mb-4'>
                                        <li>
                                            <Link to="#" onClick={(event) => {
                                                event.preventDefault();
                                                window.open('/privacy-policy', '_blank', 'toolbar=no,scrollbars=no,resizable=no,width=400,height=400');
                                            }}>Privacy Policy</Link>
                                        </li>
                                            <li>|</li>
                                            <Link to='#' onClick={(event) => {
                                                event.preventDefault();
                                                window.open('/term-and-condition', '_blank', 'toolbar=no,scrollbars=no,resizable=no,width=400,height=400');
                                            }}>Terms And Conditions</Link>
                                        </ul>
                                        <div className='scuLogo'>
                                            <img src={securityLogos} className="img-fluid w-100" />
                                        </div>
                                    </div>
                                </div>
                                {/* Col End  */}
                            </div>
                            {/* Row End  */}
                            {/* gurantee-sec begin  */}
                            <div className='gurantee-sec'>
                                <div className='row align-items-center'>
                                    <div className='col-4 col-md-3 mb-3 mb-md-0'>
                                        <figure>
                                            <img src={moneyBack} className="img-fluid" />
                                        </figure>
                                    </div>
                                    <div className='col-8 col-md-9'>
                                        <div className="media-body">
                                            <h5>100% 30 DAYS MONEY BACK GUARANTEE</h5>
                                            <p>Wholesale Healthclub comes with a 30 Days, 100% Money Back Guarantee. That means if you change your mind about this decision at any point in the next 30 days all you need to do is email us, and we’ll refund your purchase.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* gurantee-sec end  */}
                        </div>
                    </div>
                </div>


            </div>
            {/* Checkout Page End  */}
        </>
    )

}


export default CheckoutPageBeta