import React, { useState, useEffect, FieldArray } from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import axios from 'axios'
import Swal from 'sweetalert2'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGear, faDeleteLeft } from '@fortawesome/free-solid-svg-icons'
import CheckPermission from '../frontend/auth/CheckPermission';
import NoImage from '../../assests/admin/assets/img/nImage.jpg';
import {BASE_URL} from '../Config';
import { Input } from 'reactstrap';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import CustomErrorMsg from "../frontend/CustomErrorMsg";

function ListProducts() {
    CheckPermission()
    const [productList, setproductLists] = useState([]);
    const [pending, setPending] = useState(true);
    const [show, setShow] = useState(false);
    const [bimage, setBimage] = useState("");
    const [limage, setLimage] = useState("");
    const [productId, setproductId] = useState(false);
    const [search, setSearch] = useState("");
    const [searchProducts, setSearchProducts] = useState([]);
    const [cname, setCName] = useState(false);
    const [brand, setBrand] = useState([]);
    const [category, setCategory] = useState([]);
    const [Pimg, setPimg] = useState();
    const [Limg, setLimg] = useState();

    const [SliderImage1, setSliderImage1] = useState();
    const [SliderImage2, setSliderImage2] = useState();
    const [SliderImage3, setSliderImage3] = useState();
    const [SliderImage4, setSliderImage4] = useState();

    const [DescriptionImage1, setDescriptionImage1] = useState();
    const [DescriptionImage2, setDescriptionImage2] = useState();

    const [BackgroundImage, setBackgroundImage] = useState();

    const [formValues, setFormValues] = useState([{ ingredients: "", per_serve : ""}]);
    const [selectedProduct, setSelectedProduct] = useState('');
    const [loadershow, setloaderShow] = useState(false);
    const [productInstance, setProductInstance] = useState({
        pname: "",
        ptype: "0",
        price: "",
        disc: "",
        disclaimer: "",
        ingri: "",
        category: "0",
        brand: "0",
        validErr: ""
    });
    const [productType, setproductType] = useState([]);
    const [checkboxValue, setcheckboxValue] = useState(false);
    const [slugAvailable, setSlugAvailable] = useState(null);
    const [checkingSlug, setCheckingSlug] = useState(false);

    const handleSlugName = async (value) => {
        if (!value) {
            setSlugAvailable(null);
            return;
        }
        try {
            setCheckingSlug(true);
            const response = await axios.post(`/api/check-slug`,{'slug' : value});
            setSlugAvailable(response.data.status);
        } catch (error) {
            console.error('Error checking slug:', error);
        } finally {
            setCheckingSlug(false);
        }
    };

    const debounce = (func, delay) => {
        let timeout;
        return (...args) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => func(...args), delay);
        };
    };

    const debouncedHandleSlugName = debounce(handleSlugName, 300);

    useEffect(() => {
        const result = productList.filter(product => {
            if (cname) {
                setCName(false);
                return product.category_name.toLowerCase().match(search.toLowerCase());

            } else {
                return product.name.toLowerCase().match(search.toLowerCase());
            }

        })
        setSearchProducts(result);
    }, [search]);

    useEffect(() => {
        setFormValues([{ ingredients: "", per_serve : ""}]);
        const timeout = setTimeout(() => {
            getProductList();
            getCategoryList();
            getProductTypeList();
            getBrandList();
        }, 1000);
        return () => clearTimeout(timeout);
    }, []);

    const handleClose = () => {
        setShow(false)
        setSearch('')
    }
    const handleShow = () => setShow(true);
    const loaderHandleClose = () => setloaderShow(false);
    const loaderHandleShow = () => setloaderShow(true);
   
    async function getProductList() {
        try {
            const res = await axios.get(`api/listproduct`);
            setproductLists(res.data.data);
            setSearchProducts(res.data.data);
            const queryParams = new URLSearchParams(window.location.search);
            const cnameU = queryParams.get('cname');
            if (cnameU != null) {
                setCName(cnameU)
                setSearch(cnameU)
            }
            setPending(false);
        } catch (err) {
            Swal.fire({ title: 'Warning', html: err.response.data.message, icon: 'warning' });
        } finally {
            setPending(false);
        }
    }

    /*async function getProductList() {
        axios.get(`api/listproduct`).then(res => {
            console.log(res.data.data);
            setproductLists(res.data.data);
            setSearchProducts((prevProducts) => [...res.data.data]);
            const queryParams = new URLSearchParams(window.location.search);
            const cnameU = queryParams.get('cname');
            if (cnameU != null) {
                setCName(cnameU)
                setSearch(cnameU)
            }
            setPending(false);

        }).catch(err => {
            Swal.fire({
                title: 'Warning',
                html: err.response.data.message,
                icon: 'warning'
            })

        })
    }*/

    function deleteProduct(id) {
        Swal.fire({
            title: 'Are you sure you want to delete this Product?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            showCloseButton: true
        }).then((result) => {
            if (result.isConfirmed) {
                axios.put(`api/deleteproduct/${id}`).then(res => {

                    Swal.fire({
                        title: 'Deleted',
                        html: res.data.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1000
                    })
                    getProductList();
                })
            }
        })
    }

    function showSingleData(id) {
        setSlugAvailable(null);
        axios.get(`api/detailsproduct/${id}`).then(res => {
            
            setFormValues([{ ingredients: "", per_serve : ""}]);
            setproductId(res.data.id);
            let bimage = new File([''], res.data.image, { type: "image/jpg", size: 0 }, 'utf-8');
            let limage = new File([''], res.data.label_image, { type: "image/jpg", size: 0 }, 'utf-8');
            let slider_image1 = new File([''],res.data.slider_image1, { type: "image/jpg", size: 0 }, 'utf-8');
            let slider_image2 = new File([''],res.data.slider_image2, { type: "image/jpg", size: 0 }, 'utf-8');
            let slider_image3 = new File([''],res.data.slider_image3, { type: "image/jpg", size: 0 }, 'utf-8');
            let slider_image4 = new File([''],res.data.slider_image3, { type: "image/jpg", size: 0 }, 'utf-8');

            let description_image1 = new File([''],res.data.description_image1, { type: "image/jpg", size: 0 }, 'utf-8');
            let description_image2 = new File([''],res.data.description_image2, { type: "image/jpg", size: 0 }, 'utf-8');

            let background_image = new File([''],res.data.background_image, { type: "image/jpg", size: 0 }, 'utf-8');

            setPimg(BASE_URL+"whc-custom/storage/app/public/" + res.data.image);
            setLimg(BASE_URL+"whc-custom/storage/app/public/" + res.data.label_image);
            setSliderImage1(BASE_URL+"whc-custom/storage/app/public/" + res.data.slider_image1);
            setSliderImage2(BASE_URL+"whc-custom/storage/app/public/" + res.data.slider_image2);
            setSliderImage3(BASE_URL+"whc-custom/storage/app/public/" + res.data.slider_image3);
            setSliderImage4(BASE_URL+"whc-custom/storage/app/public/" + res.data.slider_image4);

            setDescriptionImage1(BASE_URL+"whc-custom/storage/app/public/" + res.data.description_image1);
            setDescriptionImage2(BASE_URL+"whc-custom/storage/app/public/" + res.data.description_image2);

            setBackgroundImage(BASE_URL+"whc-custom/storage/app/public/" + res.data.background_image);
            setSelectedProduct(''+ res.data.product_type);
            setProductInstance({
                pname : res.data.name,
                pslug : (res.data.slug === null ? '' : res.data.slug),
                ptype : res.data.product_type,
                price : res.data.price,
                jar : res.data.jar,
                capsules : res.data.capsules,
                mg : res.data.mg,
                details : res.data.details,
                product_details : res.data.product_details,
                allergen : res.data.contains_allergen,
                disc  : res.data.description,
                disclaimer: res.data.disclaimer,
                ingri: res.data.ingredients,
                category: res.data.category_id,
                brand: res.data.brand_id,
                product_usage : res.data.product_usage,
                bimage: bimage,
                limage: limage,
                slider_image1: slider_image1,
                slider_image2: slider_image2,
                slider_image3: slider_image3,
                slider_image4: slider_image4,
                description_image1: description_image1,
                description_image2: description_image2,
                background_image: background_image,
                is_productvisible: res.data.is_productvisible,
                is_productvisible_frontend: res.data.is_productvisible_frontend,
            });
            const supplementFacts = JSON.parse(res.data.supplement_facts);
            if(supplementFacts != null){
                setFormValues(supplementFacts);
            }
            handleShow();
        })
    }

    function createProductModal() {
        setSlugAvailable(null);
        setFormValues([{ ingredients: "", per_serve : ""}]);
        setProductInstance([]);
        setproductId(false);
        setPimg('');
        setLimg('');
        setSliderImage1('');
        setSliderImage2('');
        setSliderImage3('');
        setSliderImage4('');
        setDescriptionImage1('');
        setDescriptionImage2('');
        setBackgroundImage('');
        handleShow();
    }

    function getCategoryList() {
        axios.get(`api/listcategory`).then(res => {

            setCategory(res.data.data);
            // console.warn(products);
        })
    }

    function getProductTypeList() {
        axios.get(`api/dataProductType`).then(res => {
            setproductType(res.data.data);
            // console.warn(products);
        })
    }

    function getBrandList() {
        axios.get(`api/listBrand`).then(res => {

            setBrand(res.data.data);
            // console.warn(products);
        })
    }

    function updateVisibility(id) {
        axios.put(`api/changeProdVisibility/${id}`).then(res => {
            Swal.fire({
                title: 'Updated',
                html: res.data.message,
                icon: 'success',
                showConfirmButton: false,
                timer: 1000
            })
            getProductList();
        })
    }

    function updateVisibilityFrontend(id){
        axios.put(`api/changeProdVisibilityFrontend/${id}`).then(res => {
            Swal.fire({
                title: 'Updated',
                html: res.data.message,
                icon: 'success',
                showConfirmButton: false,
                timer: 1000
            })
            getProductList();
        })
    }

    let handleChange = (i, e) => {
        let newFormValues = [...formValues];
        newFormValues[i][e.target.name] = e.target.value;
        setFormValues(newFormValues);
     }
        
    let addFormFields = () => {
        setFormValues([...formValues, { ingredients: "", per_serve: "" }])
     }
    
    let removeFormFields = (i) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues)
    };

    const formSubmit = (data) => {
        handleClose();
        loaderHandleShow();
        const formdata = handleFormValues(data);
        //console.log(Object.fromEntries(formdata));
        axios.post(`api/createproduct`, formdata).then(res => {
            if (res.data.status === true) {
                setSlugAvailable(null);
                handleClose();
                loaderHandleClose();
                Swal.fire({
                    title: 'Success',
                    html: res.data.message,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1000
                })
                window.location.reload();
            }
        }).catch(err => {
            console.log(err.response)
            loaderHandleClose();
            // window.location.reload();
            Swal.fire({
                title: 'Warning',
                html: err.response.data.message,
                icon: 'warning'
            })

        });
    };

    const formUpdate = (data) => {
        handleClose();
        loaderHandleShow();
        const formdata = handleFormValues(data);
        // console.log(Object.fromEntries(formdata));
        axios.post(`api/updateproduct/${productId}`, formdata).then(res => {
            if (res.data.status === true) {
                setSlugAvailable(null);
                handleClose();
                loaderHandleClose();
                Swal.fire({
                    title: 'Success',
                    html: res.data.message,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1000
                })
                window.location.reload();
            }
        }).catch(err => {
            loaderHandleClose();
            Swal.fire({
                title: 'Warning',
                html: err.response.data.message,
                icon: 'warning'
            })
        });
    };

    const handleFormValues = (data) => {
        const formdata = new FormData();
        let productVisible = '';
        let productVisibleFrontend = '';
        if(data.productIsVisible === true){
            productVisible = 1;
        }else{
            productVisible = 0;
        }
        if(data.productIsVisibleFrontend === true){
            productVisibleFrontend = 1;
        }else{
            productVisibleFrontend = 0;
        }
        if(selectedProduct==='1'){
            formdata.append('name', data.pname);
            formdata.append('pslug', data.pslug);
            formdata.append('product_type', '' + selectedProduct);
            formdata.append('jar', data.jar);
            formdata.append('capsules', data.capsules);
            formdata.append('mg', data.mg);
            formdata.append('price', data.price);
            formdata.append('details', data.details);
            formdata.append('product_details', data.product_details);
            formdata.append('product_usage', data.product_usage);
            formdata.append('ingredients', data.ingri);
            formdata.append('category_id', data.category);
            formdata.append('brand_id', data.brand);
            formdata.append('product_is_visible', productVisible);
            formdata.append('product_is_visible_frontend', productVisibleFrontend);
            /*formdata.append("slider_image1", data.slider_image1);
            formdata.append("slider_image2", data.slider_image2);
            formdata.append("slider_image3", data.slider_image3);
            formdata.append("slider_image4", data.slider_image4);
            formdata.append("description_image1", data.description_image1);
            formdata.append("description_image2", data.description_image2);
            formdata.append("background_image", data.background_image);*/

            formdata.append('bimage', data.bimage);
            formdata.append('limage', data.limage);
            formdata.append('bimage_size', data.bimage.size);
            formdata.append('limage_size', data.limage.size);

            formdata.append("slider_image1", data.slider_image1);
            formdata.append("slider_image_size1", data.slider_image1.size);
            formdata.append("slider_image2", data.slider_image2);
            formdata.append("slider_image_size2", data.slider_image2.size);
            formdata.append("slider_image3", data.slider_image3);
            formdata.append("slider_image_size3", data.slider_image3.size);
            formdata.append("slider_image4", data.slider_image4);
            formdata.append("slider_image_size4", data.slider_image4.size);

            formdata.append("description_image1", data.description_image1);
            formdata.append("description_image_size1", data.description_image1.size);
            formdata.append("description_image2", data.description_image2);
            formdata.append("description_image_size2", data.description_image2.size);

            formdata.append("background_image", data.background_image);
            formdata.append("background_image_size", data.background_image.size);
            
            formdata.append("supplement_facts", JSON.stringify(formValues));
            formdata.append("allergen", data.allergen);
        }else{
            formdata.append('name', data.pname);
            formdata.append('pslug', data.pslug);
            formdata.append('product_type', selectedProduct);
            formdata.append('price', data.price);
            formdata.append('description', data.disc);
            formdata.append('disclaimer', data.disclaimer);
            formdata.append('ingredients', data.ingri);
            formdata.append('category_id', data.category);
            formdata.append('brand_id', data.brand);
            formdata.append('bimage', data.bimage);
            formdata.append('limage', data.limage);
            formdata.append('bimage_size', data.bimage.size);
            formdata.append('limage_size', data.limage.size);
            formdata.append('product_is_visible', productVisible);
            formdata.append('product_is_visible_frontend', productVisibleFrontend);
        }
        return formdata;
    };

    const columns = [
        {
            name: "Product Name",
            sortable: true,
            selector: row => row.name,
            cell: row => row.name
        },
        {
            name: "Product Type",
            sortable: true,
            selector: row => row.productType,
            cell: row => <span>{row.productType }</span>
        },
        {
            name: "Category",
            sortable: true,
            selector: row => row.category_name,
            cell: row => (
                <span>{row.category_name}</span>
            )
        },
        {
            name: "Brand",
            sortable: true,
            selector: row => row[0].brand_name,
            cell: row => (
                <span>{row[0].brand_name}</span>
            )
        },
        {
            name: "Price",
            cell: row => (
                <span>${row.price}</span>
            )
        },
        {
            name: "Product Image",
            cell: row => (
                <img src={row.image} width="100" height="50" />
            )
        },
        {
            name: "Visible to frontend",
            sortable: true,
            cell: row => (
                <label className="switch">
                    <input type="checkbox" data-check={row.is_productvisible_frontend} defaultChecked={row.is_productvisible_frontend === 1 ? true : false} name="productIsVisibleFrontend" onChange={() => { updateVisibilityFrontend(row.id) }}></input>
                    <span className="slider round"></span>
                </label>
            )
        },
        {
            name: "Visible to Customer Support",
            sortable: true,
            cell: row => (
                <label className="switch">
                    <input type="checkbox" defaultChecked={row.is_productvisible === 1 ? true : false} name="productIsVisible" onChange={() => { updateVisibility(row.id) }}></input>
                    <span className="slider round"></span>
                </label>
               //<span>{row.is_productvisible}</span>
            )
        },
        {
            name: "Action",
            cell: row => (
                <>
                    <button style={{ marginRight: 1 + 'em' }} className='btn btn-primary' onClick={() => { showSingleData(row.id) }} ><FontAwesomeIcon icon={faGear} title="Edit Product" /></button>
                    <button className='btn btn-danger' onClick={() => { deleteProduct(row.id) }} ><FontAwesomeIcon icon={faDeleteLeft} title="Delect Product" /></button>
                </>
            )
        }
    ]

    const handleCheckboxChange = (event) => {
        setcheckboxValue(event.target.checked);
    };

    return (
        <div>
            <div >
                <div className='modal'>
                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>   {productId ? "Update Product" : "Create Product"}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='container'>
                                <div className='row justify-content-center'>
                                    <div className='col-md-12'>
                                        <div className='card'>
                                            <div className='card-body'>
                                                <Formik
                                                    enableReinitialize = {true}
                                                    initialValues={{
                                                        pname:productInstance.pname,
                                                        pslug:productInstance.pslug, 
                                                        ptype:productInstance.ptype,
                                                        jar:productInstance.jar,
                                                        capsules:productInstance.capsules,
                                                        mg:productInstance.mg, 
                                                        price:productInstance.price,
                                                        details:productInstance.details,
                                                        product_details:productInstance.product_details,
                                                        allergen:productInstance.allergen,  
                                                        disc:productInstance.disc, 
                                                        disclaimer:productInstance.disclaimer, 
                                                        ingri:productInstance.ingri, 
                                                        category:productInstance.category, 
                                                        brand:productInstance.brand,
                                                        product_usage:productInstance.product_usage, 
                                                        bimage:productInstance.bimage, 
                                                        limage:productInstance.limage,
                                                        slider_image1:productInstance.slider_image1,
                                                        slider_image2:productInstance.slider_image2,
                                                        slider_image3:productInstance.slider_image3,
                                                        slider_image4:productInstance.slider_image4,
                                                        description_image1:productInstance.description_image1,
                                                        description_image2:productInstance.description_image2,
                                                        background_image:productInstance.background_image,
                                                        productIsVisible:productInstance.is_productvisible === 1 ? true : false,
                                                        productIsVisibleFrontend:productInstance.is_productvisible_frontend === 1 ? true : false,
                                                    }}
                                                    validationSchema={Yup.object({
                                                        pname: Yup.string().required('Product name is required'),
                                                        //pslug: Yup.string().required('Product slug is required'),
                                                        ptype: !selectedProduct && Yup.string().required('Product type is required'),
                                                        price: Yup.number()
                                                                .typeError('Price must be a number')
                                                                .positive('Price must be greater than zero')
                                                                .required('Price is required'),
                                                        disc : selectedProduct !== '1' && Yup.string().required('Description is required'),
                                                        disclaimer: selectedProduct !== '1' && Yup.string().required('Disclaimer is required'),
                                                        ingri: Yup.string().required('Ingredients is required'),
                                                        category: Yup.string().required('Please select a category'),
                                                        brand : Yup.string().required('Please select a brand'),
                                                        bimage: Yup.mixed()
                                                            .nullable()
                                                            .required('Bottle image is required')
                                                            .test('File Size',
                                                                'Upload file size is too large', (value) => !value || (value && value.size <= 1024000 * 1024000))
                                                            .test('format',
                                                                'Invalid file type. Only *.jpg,*.png,*.jpeg files acceptable. ', (value) => !value || (value && ['image/jpg', 'image/jpeg', 'image/png', 'image/webp'].includes(value.type))),
                                                        limage: Yup.mixed()
                                                            .nullable()
                                                            .required('Label image is required')
                                                            .test('File Size',
                                                                'Upload file size is too large', (value) => !value || (value && value.size <= 1024000 * 1024000))
                                                            .test('format',
                                                                'Invalid file type. Only *.jpg,*.png,*.jpeg files acceptable. ', (value) => !value || (value && ['image/jpg', 'image/jpeg', 'image/png', 'image/webp'].includes(value.type))),        
                                                    })}

                                                    onSubmit={(values, { setSubmitting, resetForm }) => {
                                                        setSubmitting(true);
                                                        //alert(JSON.stringify(values, null, 2));
                                                        
                                                        {(productId) ? formUpdate(values) : formSubmit(values); }
                                                            
                                                    }}
                                                >
                                                    {(formProps) => (
                                                        <Form>                                                            
                                                            <div className='form-group md-3'>
                                                                <label> Product Name </label>
                                                                <Field type="text" name="pname" className="form-control"></Field>
                                                                <CustomErrorMsg name="pname" />
                                                            </div>
                                                            <div className='form-group md-3'>
                                                                <label> Product Slug </label>
                                                                <Field 
                                                                    type="text" 
                                                                    name="pslug" 
                                                                    className="form-control"
                                                                    onKeyUp={(e) => {
                                                                        debouncedHandleSlugName(e.target.value);
                                                                        formProps.setFieldValue('pslug', e.target.value);
                                                                    }}
                                                                >
                                                                </Field>
                                                                {checkingSlug && <p style={{ fontSize: '14px', fontWeight: '600', color: 'green' }} className='mt-2'>Checking slug availability...</p>}
                                                                {slugAvailable === true && <p style={{ color: 'green' }} className='errMsg mt-2'>Slug is available</p>}
                                                                {slugAvailable === false && <p style={{ color: 'red' }} className='errMsg mt-2'>Slug is already taken</p>}
                                                                <CustomErrorMsg name="pslug" />
                                                            </div>
                                                            <div className='form-group md-3'>
                                                                <label> Product Type </label>
                                                                <Field as="select" value={selectedProduct} name="ptype" className="form-control" onChange={({target})=>{
                                                                    setSelectedProduct(''+target.value);
                                                                    }}>
                                                                    <option  value="">Select Product Type</option>
                                                                    {productType ? productType.map(productTypeList => {
                                                                        return (
                                                                            <option value={productTypeList.id} key={productTypeList.id}>
                                                                                {productTypeList.name}
                                                                            </option>
                                                                        );
                                                                    }) : ''}
                                                                </Field>
                                                                {!selectedProduct && <CustomErrorMsg name="ptype" />}
                                                            </div>
                                                           {selectedProduct === '1' && <div className='form-group md-3'>
                                                                <label> Jar</label>
                                                                <Field type="text" name="jar" className="form-control"></Field>
                                                            </div>}
                                                            {selectedProduct ==='1' && <div className='form-group md-3'>
                                                                <label> Capsules</label>
                                                                <Field type="text" name="capsules" className="form-control"></Field>
                                                            </div>}
                                                           {selectedProduct ==='1'&& <div className='form-group md-3'>
                                                                <label> Mg Per Capsules</label>
                                                                <Field type="text" name="mg" className="form-control"></Field>
                                                            </div>}
                                                            <div className='form-group md-3'>
                                                                <label> Price</label>
                                                                <Field type="number" name="price" className="form-control"></Field>
                                                                <CustomErrorMsg name="price" />
                                                            </div>
                                                           {selectedProduct ==='1'&& <div className='form-group md-3'>
                                                                <label> Introduction</label>
                                                                <Field as="textarea" name="details" className="form-control" placeholder="Enter product introduction"/>
                                                            </div>}
                                                            {selectedProduct ==='1' && <div className='form-group md-3'>
                                                                <label> Product Details</label>
                                                                <Field as="textarea" name="product_details" className="form-control" placeholder="Enter product details"/>
                                                            </div>}
                                                          {selectedProduct !== '1' && <div className='form-group md-3'>
                                                                <label> Description</label>
                                                                <Field type="text" name="disc" className="form-control"></Field>
                                                                <CustomErrorMsg name="disc" />
                                                            </div>}
                                                            {selectedProduct !== '1' &&  <div className='form-group md-3'>
                                                                <label> Disclaimer</label>
                                                                <Field type="text" name="disclaimer" className="form-control"></Field>
                                                                <CustomErrorMsg name="disclaimer" />
                                                            </div>}
                                                            <div className='form-group md-3'>
                                                                <label>Other Ingredients</label>
                                                                <Field type="text" name="ingri" className="form-control"></Field>
                                                                <CustomErrorMsg name="ingri" />
                                                            </div>
                                                            {selectedProduct ==='1' && <div className='form-group md-3'>
                                                                <label> Product Usage</label>
                                                                <Field as="textarea" type='text' name="product_usage" className="form-control" placeholder="Enter product usage"/>
                                                            </div>}
                                                            {selectedProduct ==='1' &&  <div className='control-head md-3'>
                                                           <label>Supplement Facts</label>
                                                            <button className="button add" type="button" onClick={() => addFormFields()}>Add</button>
                                                            </div>}
                                                            {selectedProduct ==='1' && formValues.map((element, index) => (
                                                                <div className="form-inline cust-input" key={index}>
                                                                    <div className='ci-left ci-common'>
                                                                        <label>Ingredients</label>
                                                                        <Field type="text" name="ingredients" value={element.ingredients || ""} onChange={e => handleChange(index, e)} />
                                                                    </div>
                                                                    <div className='ci-right ci-common'>
                                                                    <label>Amount Per Serving</label>
                                                                        <Field type="text" name="per_serve" value={element.per_serve || ""} onChange={e => handleChange(index, e)} />
                                                                        {
                                                                            index ? 
                                                                            <button type="button"  className="button remove" onClick={() => removeFormFields(index)}>Remove</button> 
                                                                            : null
                                                                        }
                                                                    </div>
                                                                </div>
                                                            ))}
                                                          {selectedProduct ==='1' &&  <div className='form-group md-3'>
                                                                <label> Contains Allergen</label>
                                                                <Field type="textarea" name="allergen" className="form-control"></Field>
                                                            </div>}
                                                            <div className='form-group md-3'>
                                                                <label> Category</label>
                                                                <Field as="select" name="category" className="form-control">
                                                                    <option value="">Select Category</option>
                                                                    {category.map(category => {
                                                                        return (
                                                                            <option value={category.id} key={category.id}>
                                                                                {category.name}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </Field>
                                                                <CustomErrorMsg name="category" />
                                                            </div>
                                                            <div className='form-group md-3'>
                                                                <label> Brand</label>
                                                                <Field as="select" name="brand" className="form-control">
                                                                    <option value="">Select Brand</option>
                                                                    {brand.map(brand => {
                                                                        return (
                                                                            <option value={brand.id} key={brand.id}>
                                                                                {brand.brand_name}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </Field>
                                                                <CustomErrorMsg name="brand" />
                                                            </div>
                                                            <div className='form-group md-3'>
                                                                <label> Is visible to frontend </label>
                                                                <label className="switch" style={{marginLeft:16}}>
                                                                    <Field type="checkbox" name="productIsVisibleFrontend" onClick={(event) => formProps.setFieldValue("productIsVisibleFrontend", event.target.checked)} onChange={handleCheckboxChange}></Field>
                                                                    <span className="slider round"></span>
                                                                </label>
                                                                <CustomErrorMsg name="productIsVisibleFrontend" />
                                                            </div>
                                                            <div className='form-group md-3'>
                                                                <label> Is visible to customer support </label>
                                                                <label className="switch" style={{marginLeft:16}}>
                                                                    <Field type="checkbox" name="productIsVisible" onClick={(event) => formProps.setFieldValue("productIsVisible", event.target.checked)} onChange={handleCheckboxChange}></Field>
                                                                    <span className="slider round"></span>
                                                                </label>
                                                                {/* <Field type='checkbox' name="productIsVisible" id="productIsVisible"></Field> */}
                                                                <CustomErrorMsg name="productIsVisible" />
                                                            </div>
                                                            <div className='form-group md-3'>
                                                                <label>Bottle Image</label>
                                                                <Input type="file" name="bimage" className="form-control" onChange={(event) => formProps.setFieldValue("bimage", event.target.files[0])}></Input>
                                                                <img width={100} src={Pimg} />
                                                                <CustomErrorMsg name="bimage" />
                                                            </div>
                                                            <div className='form-group md-3'>
                                                                <label>Label Image</label>
                                                                <Input type="file" name="limage" className="form-control" onChange={(event) => formProps.setFieldValue("limage", event.target.files[0])}></Input>
                                                                <img width={100} src={Limg?.includes('null') ? NoImage : Limg } />
                                                                <CustomErrorMsg name="limage" />
                                                            </div>
                                                            {selectedProduct ==='1' &&  <div className='form-group md-3'>
                                                                <label>Slider Image1</label>
                                                                <Input type="file" name="slider_image1" className="form-control" multiple onChange={(event) => formProps.setFieldValue("slider_image1", event.target.files[0])}></Input>
                                                                <img width={100} src={SliderImage1?.includes('null') ? NoImage : SliderImage1 } />
                                                            </div>}
                                                          {selectedProduct ==='1' &&  <div className='form-group md-3'>
                                                                <label>Slider Image2</label>
                                                                <Input type="file" name="slider_image2" className="form-control" multiple onChange={(event) => formProps.setFieldValue("slider_image2", event.target.files[0])}></Input>
                                                                <img width={100} src={SliderImage2?.includes('null') ? NoImage : SliderImage2 } />
                                                            </div>}
                                                            {selectedProduct ==='1' &&  <div className='form-group md-3'>
                                                                <label>Slider Image3</label>
                                                                <Input type="file" name="slider_image3" className="form-control" multiple onChange={(event) => formProps.setFieldValue("slider_image3", event.target.files[0])}></Input>
                                                                <img width={100} src={SliderImage3?.includes('null') ? NoImage : SliderImage3 } />
                                                            </div>}
                                                            {selectedProduct ==='1' &&   <div className='form-group md-3'>
                                                                <label>Slider Image4</label>
                                                                <Input type="file" name="slider_image4" className="form-control" multiple onChange={(event) => formProps.setFieldValue("slider_image4", event.target.files[0])}></Input>
                                                                <img width={100} src={SliderImage4?.includes('null') ? NoImage : SliderImage4 } />
                                                            </div>}
                                                            {selectedProduct ==='1' && <div className='form-group md-3'>
                                                                <label>Description Image1</label>
                                                                <Input type="file" name="description_image1" className="form-control" onChange={(event) => formProps.setFieldValue("description_image1", event.target.files[0])}></Input>
                                                                <img width={100} src={DescriptionImage1?.includes('null') ? NoImage : DescriptionImage1 } />
                                                            </div>}
                                                            {selectedProduct ==='1' &&  <div className='form-group md-3'>
                                                                <label>Description Image2</label>
                                                                <Input type="file" name="description_image2" className="form-control" onChange={(event) => formProps.setFieldValue("description_image2", event.target.files[0])}></Input>
                                                                <img width={100} src={DescriptionImage2?.includes('null') ? NoImage : DescriptionImage2 } />
                                                            </div>}
                                                         {selectedProduct ==='1' &&  <div className='form-group md-3'>
                                                                <label>Background Image</label>
                                                                <Input type="file" name="background_image" className="form-control" onChange={(event) => formProps.setFieldValue("background_image", event.target.files[0])}></Input>
                                                                <img width={100} src={BackgroundImage?.includes('null') ? NoImage : BackgroundImage } />
                                                            </div>}
                                                            <br />
                                                            <div className='form-group md-3 text-end'>
                                                                <button type="submit" className="btn submitBtn btn-primary">Save</button>
                                                            </div>
                                                        </Form>
                                                    )}
                                                </Formik>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        {/* <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={productId ? updateProduct : submitProduct}>
                                {productId ? "Update" : "Save"}
                            </Button>
                        </Modal.Footer> */}
                    </Modal>
                    <Modal show={loadershow} className="text-center">
                        <Modal.Header>
                            <Modal.Title></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Spinner animation="grow" />
                        </Modal.Body>
                        <Modal.Footer></Modal.Footer>
                    </Modal>
                </div>
            </div>
            <div className='container'>
                <div className='col-md-12 pl-3'>
                    <div className="pageheader card mb-4">
                        <div className="card-body row align-items-center">
                            <div className="page-name col-md-3">Product List</div>
                            <div className="buttun_div  col-md-9">
                                <Button variant="primary" onClick={createProductModal}>Create Product</Button>
                            </div>
                        </div>
                    </div>
                    <div className='card'>
                        <div className='card-body'>
                            <div className='datatable'>
                                <DataTable
                                    key={searchProducts.length}
                                    noDataComponent="Product table is empty" //or your component
                                    columns={columns}
                                    data={searchProducts}
                                    progressPending={pending}
                                    pagination
                                    fixedHeader
                                    subHeader
                                    subHeaderComponent={
                                        <input type="text" placeholder="search" value={search} onChange={(e) => { setSearch(e.target.value) }} />
                                    }
                                    subHeaderAlign="right"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ListProducts;

//initial => description and desclimer should not render
// others => all validated fields should render