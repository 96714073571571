import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation }  from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import {BASE_URL,stateList,GetCardType} from '../Config';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import CustomErrorMsg from "../frontend/CustomErrorMsg";
import moment from "moment";
import useStore from '../../store';
import axios from "axios";
import Swal from "sweetalert2";

import '../../assests/funnel/assets/css/acv-mobile-checkout.css';

import wholesaleLogo from '../../assests/funnel/assets/images/wholesale-logo.png';
import godaddyimg from '../../assests/funnel/assets/images/godaddyimg2.png';
import secureLogo from '../../assests/funnel/assets/images/secureLogo.png';
import safe from '../../assests/funnel/assets/images/safe.png';
import secureicons from '../../assests/funnel/assets/images/or-secureicons.jpg';
import guaranteeIco from '../../assests/funnel/assets/images/guarantee-ico-30days.png';
import socialIcons from '../../assests/Lander/assets/images/social-icons.jpg';
import websiteSecure from '../../assests/Lander/assets/images/website-secure.png';
import ca from '../../assests/Lander/assets/images/ca.png';
import pack1 from '../../assests/Lander/assets/images/package-1.png';
import pack2 from '../../assests/Lander/assets/images/package-2.png';
import pack3 from '../../assests/Lander/assets/images/product-checkout.png';
import visa from '../../assests/Lander/assets/images/visa.png';
import mastercard from '../../assests/Lander/assets/images/mastercard.png';
import discover from '../../assests/Lander/assets/images/discover.png';
import amex from '../../assests/Lander/assets/images/amex.png';
import cvvImg from '../../assests/Lander/assets/images/cvv-image.png';
import paypal from '../../assests/frontend/assets/images/paypal.png';
import paypalBtn from '../../assests/frontend/assets/images/paypal-btn.png';
import upArrow from '../../assests/Lander/assets/images/up-arrow-white.png';


function AcvMobileCheckout(props){
    const ref = useRef(null);
    const location = useLocation();
    const currentYear = new Date().getFullYear();
    const years = Array.from(new Array(10), (val, index) => currentYear + index);
    let affid = new URLSearchParams(location.search).get('affid');
    let sid = new URLSearchParams(location.search).get('sid');
    let s1 = new URLSearchParams(location.search).get('s1');
    let s2 = new URLSearchParams(location.search).get('s2');
    let s3 = new URLSearchParams(location.search).get('s3');
    let click_id = new URLSearchParams(location.search).get('click_id');
    let notes = new URLSearchParams(location.search).get('notes');
    const [selectedPayment, setSelectedPayment] = useState('creditCard');
    let params = location.search;
    const [checkoutFunnelData, setCheckoutFunnelData] = useState({});
    const [SMToggle, setSMToggle] = useState(false);
    const [SMAddonToggle, setSMAddonToggle] = useState(true);
    const [selectedProduct, setSelectedProduct] = useState(1);
    const [price, setPrice] = useState(0);
    const [retail, setReatil] = useState(0);
    const [save, setSave] = useState(0);
    const [total, setTotal] = useState(0);
    const [shipping, setShipping] = useState(0);
    const [pID, setPID] = useState(0);
    const [sapphirePrice, setSapphirePrice] = useState(0.00);
    const [eachFirstProductPrice, setEachFirstProductPrice] = useState(0);
    const [eachSecondProductPrice, setEachSecondProductPrice] = useState(0);
    const [eachThirdProductPrice, setEachThirdProductPrice] = useState(0);
    const [FPDiv, setFPDiv] = useState(true)
    const [SPDiv, setSFPDiv] = useState(false)
    const [TPDiv, setTPDiv] = useState(false);
    const [shippingDiv, setShippingDiv] = useState(false);
    const [loadershow, setloadershow] = useState(false);
    const [activeClass, setActiveClass] = useState('');
    const [divDisplay, setDivDisplay] = useState('');
    const [second, setSecond] = React.useState(59);
    const [minute, setMInute] = React.useState(9);
    const afterLogin = useStore((state) => state.afterLogin);
    const [cardType, setCardType] = useState("");
    const [showDiv, setShowDiv] = useState(false);
    const [checkboxValue, setcheckboxValue] = useState(true);
    let localURL = window.location.origin.includes('localhost') ? 'http://localhost:3000/' : window.location.origin+'/';
    let URL = window.location.href;
    let url_slug = URL.substring(URL.lastIndexOf('/') + 0);
    let indexOfQuestionMark = url_slug.indexOf('?');
    let urlSlug;
    if (indexOfQuestionMark !== -1) {
        urlSlug = url_slug.substring(0, indexOfQuestionMark);
    } else {
        urlSlug = url_slug;
    }
    // Modal 
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    //State List ;
    const options = stateList;
    const [isFormVisible, setFormVisible] = useState(false);

    const handleClickRef = () => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const handleCountryChange2 = (country_id) => {
        axios.get(`api/get-state/${country_id}`).then(res => {
            if(res.data.status === true) {
                setStates(res.data.data);
            }
        })
    };

    useEffect(() => { 
        setIntialData();
        handleCountryChange2('239');
    }, [params.slug]);

    React.useEffect(() => {
        second > 0 && setTimeout(() => setSecond(second - 1), 1000);
        second == 0 && minute != 0 && setTimeout(() => setMInute(minute - 1), 1000);
        second == 0 && setTimeout(() => setSecond(59), 1000);
        minute == 0 && second == 0 && setTimeout(() => setMInute(4), 1000);
    }, [second]);

    const [states, setStates] = useState([]);
    
    const handleCountryChange = (event) => {
        const country_id = event.target.value;
        axios.get(`api/get-state/${country_id}`).then(res => {
            if(res.data.status === true) {
                setStates(res.data.data);
            }
        })
    };
    

    function setIntialData() {
        //console.log(props);
        setActiveClass(1);
        setDivDisplay(1);
        setCheckoutFunnelData(props.data);
        setPrice(props.data[0].first_product_price);
        setReatil(props.data[0].first_product_retail);
        setSave(props.data[0].first_product_save);
        setShipping(0);
        setTotal(parseFloat((props.data[0].first_product_price)) + parseFloat(shipping));
        setPID((props.data[0].first_product_id));
        setSapphirePrice(0.00);
        localStorage.setItem('ProductId',props.data[0].first_product_id);
    }

    function setOrderSummery(productPrice, productId, productRetailPrice, productPriceSave, productShippingId, ProductDiv, eachPrice) {
        setFormVisible(true);
        if (ProductDiv == 1) {
            setSelectedProduct(1);
            setFPDiv(true);
            setSFPDiv(false);
            setTPDiv(false);
            localStorage.setItem('ProductId',productId);
            setActiveClass(3);
            setDivDisplay(3);
            handleClickRef();

            setPrice(productPrice);
            setReatil(productRetailPrice);
            setSave(productPriceSave);
            setShipping(0);
            setTotal(parseFloat(productPrice)+parseFloat(0.00));
            setPID(productId);
        }
        if (ProductDiv == 2) {
            setSelectedProduct(2);
            setFPDiv(false);
            setSFPDiv(true);
            setTPDiv(false);
            localStorage.setItem('ProductId',productId);
            setActiveClass(3);
            setDivDisplay(3);
            handleClickRef();

            setPrice(productPrice);
            setReatil(productRetailPrice);
            setSave(productPriceSave);
            setShipping(0);
            setTotal(parseFloat(productPrice)+parseFloat(0.00));
            setPID(productId);
        }
        if (ProductDiv == 3) {
            setSelectedProduct(3);
            setFPDiv(false);
            setSFPDiv(false);
            setTPDiv(true);
            setActiveClass(3);
            setDivDisplay(3);
            handleClickRef();

            if(!SMAddonToggle){
                setPrice(productPrice);
                setSave(productPriceSave);
                setShipping(9.95);
                setTotal(parseFloat(productPrice)+parseFloat(9.95));
                setReatil(parseFloat(productPrice)+parseFloat(9.95));
                setPID(productId);
                setShippingDiv(true);
                localStorage.setItem('ProductId',223);
                setEachThirdProductPrice(eachPrice);
                
            } else {
                setPrice(productPrice);
                setSave(productPriceSave);
                setShipping(0);
                setTotal(parseFloat(productPrice)+parseFloat(0.00));
                setReatil(parseFloat(productPrice)+parseFloat(0.00));
                setPID(productId);
                setShippingDiv(false);
                localStorage.setItem('ProductId',productId);
            }
        }
    }

    function handleSPAddon(selectedProduct) {
        setSMAddonToggle(!SMAddonToggle);
        //console.log(SMAddonToggle);
        if (selectedProduct == 3) {
            if (!SMAddonToggle) {
                setShipping(0);
                setTotal(parseFloat(props.data[0].third_product_price)+parseFloat(0.00));
                setReatil(parseFloat(props.data[0].third_product_price)+parseFloat(0.00));
                localStorage.setItem('ProductId',checkoutFunnelData[0].third_product_id);
            } else {
                setShipping(9.95);
                setTotal(parseFloat(props.data[0].third_product_price)+parseFloat(9.90));
                setReatil(parseFloat(props.data[0].third_product_price)+parseFloat(9.90));
                //localStorage.setItem('ProductId',97);
                localStorage.setItem('ProductId',223);
            }
        }
    }

    const handleCheckboxChange = (event) => {
        setcheckboxValue(event.target.checked);
        setShowDiv(!event.target.checked); 
    };

    const Form1Schema = Yup.object().shape({
        firstName: Yup.string().required('First name is required'),
        lastName: Yup.string().required('Last name is required'),
        email: Yup.string().email("Email is required").max(255).required("Please enter a valid email"),
        phone: Yup.string().required('Phone no is required')
            .matches(/^[0-9]+$/, "Please enter only number!")
            .min(10, 'Please enter a valid contact number!')
            .max(10, 'Please enter a valid contact number!')
    })

    function prospectCreation(values) {
        let campaign_id = '44';
        setloadershow(true);
        const formdata = new FormData();
        formdata.append('firstName',values.firstName);
        formdata.append('lastName',values.lastName);
        formdata.append('email',values.email);
        formdata.append('phone',values.phone);
        formdata.append('tag1','diet');
        formdata.append('tag2','diet-prospect');
        formdata.append('shippingAddress',values.shippingAddress1);
        formdata.append('shippingCity',values.shippingCity);
        formdata.append('shippingCountry',values.country === '239' ? 'US' : 'CA');
        formdata.append('shippingState',values.shippingState);
        formdata.append('shippingZip',values.shippingZip);
        formdata.append('campaign_id',campaign_id);

        let affid = new URLSearchParams(location.search).get('affid');
        let sid = new URLSearchParams(location.search).get('sid');
        let s1 = new URLSearchParams(location.search).get('s1');
        let s2 = new URLSearchParams(location.search).get('s2');
        let s3 = new URLSearchParams(location.search).get('s3');
        let click_id = new URLSearchParams(location.search).get('click_id');
        let notes = new URLSearchParams(location.search).get('notes');

        localStorage.setItem('affid',affid);
        localStorage.setItem('sid',sid);
        localStorage.setItem('s1',s1);
        localStorage.setItem('s2',s2);
        localStorage.setItem('s3',s3);
        localStorage.setItem('click_id',click_id);
        localStorage.setItem('notes',notes);

        formdata.append('affid', affid);
        formdata.append('sid', sid);
        formdata.append('s1', s1);
        formdata.append('s2', s2);
        formdata.append('s3', s3);
        formdata.append('click_id', click_id);
        formdata.append('notes', notes);
        
        //console.log(Object.fromEntries(formdata));

        axios.post(`api/maropost_tag_add`, formdata).then(res => {
            if (res.data.status === true) {
                axios.post(`api/create-prospect`, formdata).then(result => {
                    if(result.data.status === true) {
                        localStorage.setItem('prospectId',result.data.prospectId);
                        setActiveClass(4);
                        setDivDisplay(4);
                        handleClickRef();
                        setloadershow(false);
                    }
                })
            }
        }).catch(err => {
            Swal.fire({
                title: 'Warning',
                html: err.response.data.message,
                icon: 'warning'
            })
        });
    }

    const handleForm1Submit = (values,{ setSubmitting }) => {
        setloadershow(true);
        setSubmitting (true);
        localStorage.setItem('firstName', values.firstName);
        localStorage.setItem('lastName', values.lastName);
        localStorage.setItem('email', values.email);
        localStorage.setItem('phone', values.phone);
        setActiveClass(2);
        setDivDisplay(2);
        setloadershow(false);
    }

    const handlePaymentSelection = (value) => {
        setSelectedPayment(value);
    };

    function confirmSubmit(data) {
        setloadershow(true);
        let expirationDate = data.cc_month+data.cc_year.slice(-2);
        localStorage.setItem('method','normal');
        const formdata = new FormData();
        formdata.append('product_id', localStorage.getItem('ProductId'));
        if (checkoutFunnelData[0].membership_add_on == 1) {
            if (SMAddonToggle) {
                formdata.append('is_membership', 41);
                formdata.append('combine_product', 1);
                formdata.append('combine_product_id', 177);  
            }
            else {
                formdata.append('is_membership', 0);
            }
        }
        else {
            formdata.append('is_membership', 0);
        }
        formdata.append('first_name',localStorage.getItem('firstName'));
        formdata.append('last_name',localStorage.getItem('lastName'));
        formdata.append('email',localStorage.getItem('email'));
        formdata.append('phone',localStorage.getItem('phone'));
        formdata.append('address',localStorage.getItem('shippingAddress1'));
        formdata.append('city',localStorage.getItem('shippingCity'));
        formdata.append('state',localStorage.getItem('shippingState'));
        formdata.append('zip',localStorage.getItem('shippingZip'));
        formdata.append('country',localStorage.getItem('country'));
        formdata.append('creditCardNumber', data.ccnumber);
        formdata.append('expirationDate', expirationDate);
        formdata.append('cvv', data.cvv);
        formdata.append('creditCardType', data.card_type);
        formdata.append('affid', data.affid);
        formdata.append('sid', data.sid);
        formdata.append('s1', data.s1);
        formdata.append('s2', data.s2);
        formdata.append('s3', data.s3);
        formdata.append('click_id', data.click_id);
        formdata.append('notes', data.notes);

        formdata.append('billingSameAsShipping', data.billingSameAsShipping);
        formdata.append('shipping_address', data.shipping_address);
        formdata.append('shipping_city', data.shipping_city);
        formdata.append('shipping_state', data.shipping_state);
        formdata.append('shipping_zip', data.shipping_zip);
        formdata.append('shipping_country', data.shipping_country);

        if(localStorage.getItem('prospectId')){
            formdata.append('prospectId', localStorage.getItem('prospectId'));
        }
        formdata.append('method',localStorage.getItem('method'));
        formdata.append('funnel', 'acvgummies2');
        localStorage.setItem('total', total);
        localStorage.setItem('affid', data.affid);
        localStorage.setItem('sid', data.sid);
        localStorage.setItem('s1', data.s1);
        localStorage.setItem('s2', data.s2);
        localStorage.setItem('s3', data.s3);
        localStorage.setItem('click_id', data.click_id);
        localStorage.setItem('notes', data.notes);
        //console.log(Object.fromEntries(formdata));
        axios.post(`api/place-funnel-order-with-prospect`, formdata).then(res => {
            // console.log(res);
            
            if (res.data.status === true) {
                sessionStorage.setItem('yp', res.data.data.user_info.yp);
                localStorage.setItem('orderId', res.data.data.order_id);
                localStorage.setItem('membership_orderId', res.data.membership ? res.data.membership.order_id : false);
                localStorage.setItem('authToken', res.data.data.member_token);
                localStorage.setItem('permission', res.data.data.user_info.account_permission);
                localStorage.setItem('userId', res.data.data.user_info.user_id);
                localStorage.setItem('userEmal', res.data.data.user_info.email);

                axios.get(`api/check_user_membership/${localStorage.getItem('email')}`).then(result => {
                    //console.log(result.data);
                    let is_membership = 0;
                    if(parseInt(result.data) === 1){
                        is_membership = 1;
                    } else {
                        is_membership = 0;
                    }
                    afterLogin(res.data.data.user_info.account_permission, res.data.data.user_info.user_type,res.data.data.user_info.user_id,res.data.data.user_info,is_membership);
                    if (localStorage.getItem('up1_s')) {
                        window.location.href = '/' + localStorage.getItem('up1_s')+params;
                    }else {
                         window.location.href = '/' + localStorage.getItem('con_s')+params;
                    }
                });
            }
            else {
                setloadershow(false);
                //console.log(res);
                Swal.fire({
                    title: res.data.message,
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33'
                })
            }
        })
                                                    
    }

    function setCreditCardType(CCNumber){
        if(CCNumber.length>=15){
            //console.log(GetCardType(CCNumber));
            setCardType(GetCardType(CCNumber));
            document.getElementById('card_type').value = GetCardType(CCNumber);
        }
    }

    function PayPalHandler(){
        if(localStorage.getItem('firstName')){
            const formdata = new FormData();
            let product_id = localStorage.getItem('ProductId');
            let is_membership = '';
            if (checkoutFunnelData[0].membership_add_on == 1) {
                if (SMAddonToggle) {
                    is_membership = 41;
                    localStorage.setItem("is_membership",41);
                    localStorage.setItem('combine_product', 1);
                    localStorage.setItem('combine_product_id', 177);
                    
                }
                else {
                    is_membership = 0;
                    localStorage.setItem("is_membership",0);
                }
            }

            let firstName = localStorage.getItem('firstName');
            let lastName = localStorage.getItem('lastName');
            let address = localStorage.getItem('shippingAddress1');
            let zip = localStorage.getItem('shippingZip');
            let state = localStorage.getItem('shippingState');
            let city = localStorage.getItem('shippingCity');
            let phone = localStorage.getItem('phone');
            let email = localStorage.getItem('email');
            let country = localStorage.getItem('country');
            let alt_pay_url = localURL+localStorage.getItem('up1_s');
            let prospectId = (localStorage.getItem('prospectId') ? localStorage.getItem('prospectId') : '');
            let combine_product = localStorage.getItem('combine_product');
            let combine_product_id = localStorage.getItem('combine_product_id');
            let funnel = 'acvgummies2';

            /* affiliate params*/
            let affid = new URLSearchParams(location.search).get('affid');
            let sid = new URLSearchParams(location.search).get('sid');
            let s1 = new URLSearchParams(location.search).get('s1');
            let s2 = new URLSearchParams(location.search).get('s2');
            let s3 = new URLSearchParams(location.search).get('s3');
            let click_id = new URLSearchParams(location.search).get('click_id');
            let notes = new URLSearchParams(location.search).get('notes');

            localStorage.setItem('affid',affid);
            localStorage.setItem('sid',sid);
            localStorage.setItem('s1',s1);
            localStorage.setItem('s2',s2);
            localStorage.setItem('s3',s3);
            localStorage.setItem('click_id',click_id);
            localStorage.setItem('notes',notes);
            localStorage.setItem('funnel',funnel);

            
            /*window.location.href=BASE_URL+'paypal?product_id='+product_id+'&is_membership='+is_membership+'&firstName='+firstName+'&lastName='+lastName+'&address='+address+'&zip='+zip+'&state='+state+'&city='+city+'&phone='+phone+'&email='+email+'&country='+country+'&alt_pay_url='+alt_pay_url;*/
            window.location.href=BASE_URL+
            'whc-custom/public/api/paypal?product_id='+product_id+
            '&is_membership='+is_membership+
            '&firstName='+firstName+
            '&lastName='+lastName+
            '&address='+address+
            '&zip='+zip+
            '&state='+state+
            '&city='+city+
            '&phone='+phone+
            '&email='+email+
            '&country='+country+
            '&alt_pay_url='+alt_pay_url+
            '&affid='+affid+
            '&sid='+sid+
            '&s1='+s1+
            '&s2='+s2+
            '&s3='+s3+
            '&click_id='+click_id+
            '&notes='+notes+
            '&prospectId='+prospectId+
            '&combine_product='+combine_product+
            '&combine_product_id='+combine_product_id+
            '&funnel='+funnel;
        } else {
            Swal.fire({
                title: "Please create prospect",
                showCancelButton: false,
                showConfirmButton: true,
                confirmButtonText: 'OK',
                cancelButtonText: "Cancel",
                confirmButtonColor: '#7066e0',
                cancelButtonColor: '#18aebf',
                showCloseButton: true,
    
            }).then((result) => {
                if (result.isConfirmed) {
                    if(urlSlug === '/nootropic2p-checkout'){
                        window.location.href=BASE_URL+'nootropic2p';
                    }
                }
            })
        }
        
    }

    return(
        <>
            {Object.values(checkoutFunnelData).length !== 0 ? <><div className={loadershow ? 'loading' : 'd-none'}></div>
            <div className={loadershow ? 'loading' : 'd-none'}></div>
            <div className="mobileCheckout acvMobileCheckout" ref={ref}>
                <div className="m-wrapper">
                    <div className="container">
                        <div className="m-check-row">
                            {/* Left Begin  */}
                            <div className="m-check-left">
                                {/* Top Begin  */}
                                <div className="acvMob-top" id="top-1">
                                    <div className="acvMob-logo mb-4 text-center">
                                        <img src={wholesaleLogo} alt="Logo" />
                                    </div>
                                    <div className="fastDelivery">
                                        <img alt="" src={godaddyimg} className="img-fluid" />
                                    </div>
                                    <div className="breadcrumbs">
                                        <ul className="breadcrumbs__list">
                                            <li className={"breadcrumbs__item breadcrumbs__item_1 "+(activeClass === 1 ? 'breadcrumbs__item_active' : '')}>
                                                <span>Qualify Now</span>
                                            </li>
                                            <li className={"breadcrumbs__item breadcrumbs__item_2 "+(activeClass === 2 ? 'breadcrumbs__item_active' : '')}>
                                                <span>Select Package</span>
                                            </li>
                                            <li className={"breadcrumbs__item breadcrumbs__item_3 "+((activeClass === 3) || (activeClass === 4)? 'breadcrumbs__item_active' : '')}>
                                                <span>Confirm Order</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <p className={divDisplay === 1 ? 'Claim text-center' : 'Claim text-center d-none'}>
                                        <b className="claimTitle">Claim Your KETO Package Today!</b>                                        
                                        <strong>
                                            At 10x the Fat Burn,<br />
                                            You Won’t Believe it’s All Natural!
                                        </strong>
                                    </p>
                                </div>
                                {/* Top End  */}

                                {/* Form Div Begin  */}
                                <div className={(divDisplay === 1 ? 'form-partial mob-check-form mb-4 px-3' : 'form-partial mob-check-form mb-4 px-3 d-none')} id="initialForm">
                                    <Formik 
                                        initialValues={{
                                            firstName: '',
                                            lastName: '',
                                            email: '',
                                            phone: '',
                                        }}
                                        validationSchema={Form1Schema}
                                        onSubmit={handleForm1Submit}
                                    >
                                        {({ errors, touched, setFieldValue, isSubmitting }) => (
                                        <Form name="qualify_form1" className="kform form123" id="theForm">                        
                                            <div className="lead-form-wrapper">
                                                <div className="columns form-holder">
                                                    <label className="mb-1">First Name:</label>
                                                    <Field 
                                                        name="firstName" 
                                                        className="form-control required" 
                                                        placeholder="First Name*"
                                                        data-placement="auto left"
                                                        id="fields_fname"
                                                        type="text" 
                                                    />
                                                    {touched.firstName && !errors.firstName && <div className="accept-icon"/>}
                                                    <CustomErrorMsg name="firstName" />
                                                </div>
                                                <div className="columns form-holder">
                                                    <label className="mb-1">Last Name:</label>
                                                    <Field 
                                                        className="form-control required" 
                                                        data-placement="auto left" 
                                                        name="lastName" 
                                                        id="fields_lname" 
                                                        placeholder="Last Name*" 
                                                        title="Last Name*" 
                                                        type="text"
                                                    />
                                                    {touched.lastName && !errors.lastName && <div className="accept-icon"></div>}
                                                    <CustomErrorMsg name="lastName" />
                                                </div>
                                                <div className="columns form-holder">
                                                    <label className="mb-1">Email:</label>
                                                    <Field 
                                                        className="form-control required" 
                                                        data-placement="auto left" 
                                                        name="email" 
                                                        id="fields_email" 
                                                        placeholder="Email*" 
                                                        title="Email" 
                                                        type="email"
                                                    />
                                                    {touched.email && !errors.email && <div className="accept-icon"/>}
                                                    <CustomErrorMsg name="email" />
                                                </div>
                                                <div className="columns form-holder">
                                                    <label className="mb-1">Phone:</label>
                                                    <Field 
                                                        className="form-control required" 
                                                        data-placement="auto left" 
                                                        name="phone" 
                                                        id="fields_phone" 
                                                        placeholder="Phone Number*" 
                                                        title="Phone Number" 
                                                        type="tel" 
                                                        maxLength="10"
                                                    />
                                                    {touched.phone && !errors.phone && <div className="accept-icon"/>}
                                                    <CustomErrorMsg name="phone" />
                                                </div>
                                                <button type="submit" className="partialsubmitbutton" disabled={isSubmitting}> Qualify For My Order » </button>
                                            </div>
                                        </Form>
                                        )}
                                    </Formik>                    
                                </div>
                                {/* Form Div End  */}

                                {/* Select Package Begin  */}
                                <div className={(divDisplay === 2) ? 'select-package-div' : 'select-package-div d-none'}>
                                    <p className="packageTitle text-center"><b>Approved</b></p>
                                    <div className="linebreak my-3"></div>

                                    {/* Pack Wrapper  */}
                                    <div className="packWrapper">
                                        {/* <!-- Pack 1  --> */}
                                        <div className={FPDiv ? 'package package1 package_active' : 'package package1'} onClick={() => { setOrderSummery(checkoutFunnelData[0].first_product_price, checkoutFunnelData[0].first_product_id, checkoutFunnelData[0].first_product_retail, checkoutFunnelData[0].first_product_save, checkoutFunnelData[0].first_product_shipping_id, 1, checkoutFunnelData[0].first_product_each)}}>
                                            <div className="package__left">
                                                <span className="package__name">Package 1</span>
                                                <span className="package__coll">{checkoutFunnelData?.[0]?.first_product_name}</span>
                                                <span className="package__free">{checkoutFunnelData?.[0]?.first_product_label}*</span>
                                                <p className="retail-price-mobile">Retail Price : <span className="text_red">${checkoutFunnelData?.[0]?.first_product_retail}</span><br/>
                                                Your Price : ${checkoutFunnelData?.[0]?.first_product_price} <br/>- Save ${checkoutFunnelData?.[0]?.first_product_save}
                                                </p>
                                                <span className="package__price">
                                                    <p className="price d-inline-block">${checkoutFunnelData?.[0]?.first_product_each}</p>
                                                    <span>/bottle</span>
                                                </span>
                                                <span className="package__stock">In Stock – Sell Out Risk: High</span>
                                                <span className="save-label">save over <span>50%</span></span>
                                            </div>
                                            <div className="package__right">
                                                <div className="bottle-block clearfix">
                                                    <div className="packItem">
                                                        <img src={pack1} className="img-fluid" alt="" />
                                                    </div>
                                                </div>
                                                <div className="retail-block">
                                                <span className="package__retail">Retail: <span className="retail-price">${checkoutFunnelData?.[0]?.first_product_each}</span>/bottle</span>
                                                <span className="shipping-block"><span>Free shipping</span></span>
                                                </div>
                                            </div>
                                            <span className="best-choise">Best <br /> choice</span>
                                            <span className="package-btn btn_pulse">yes – choose this package <span>»</span></span>
                                        </div>
                                        {/* <!-- Pack 1 End --> */}
                                        {/* <!-- Line Break  --> */}
                                        <div className="linebreak my-2"></div>

                                        {/* <!-- Pack 2  --> */}
                                        <div className={SPDiv ? 'package package2 package_active' : 'package package2'} onClick={() => { setOrderSummery(checkoutFunnelData[0].second_product_price, checkoutFunnelData[0].second_product_id, checkoutFunnelData[0].second_product_retail, checkoutFunnelData[0].second_product_save, checkoutFunnelData[0].second_product_shipping_id, 2, checkoutFunnelData[0].second_product_each)}}>
                                            <div className="package__left">
                                                <span className="package__name">Package 2</span>
                                                <span className="package__coll">{checkoutFunnelData?.[0]?.second_product_name}</span>
                                                <span className="package__free">{checkoutFunnelData?.[0]?.second_product_label}*</span>
                                                <p className="retail-price-mobile">Retail Price : <span className="text_red">${checkoutFunnelData?.[0]?.second_product_retail}</span><br/>
                                                Your Price : ${checkoutFunnelData?.[0]?.second_product_price} <br/>- Save ${checkoutFunnelData?.[0]?.second_product_save}
                                                </p>
                                                <span className="package__price">
                                                    <p className="price">${checkoutFunnelData?.[0]?.second_product_each}</p>
                                                    <span>/bottle</span>
                                                </span>
                                                <span className="package__stock">In Stock – Sell Out Risk: High</span>
                                            </div>
                                            <div className="package__right">
                                                <div className="bottle-block clearfix">
                                                    <div className="packItem">
                                                        <img src={pack2} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="save-block">
                                                        <span className="save-block__title">Save</span>
                                                        <span className="save-block__price save-price">${checkoutFunnelData?.[0]?.second_product_save}</span>
                                                    </div>
                                                </div>
                                                <div className="retail-block">
                                                <span className="package__retail">Retail: <span className="retail-price">${checkoutFunnelData?.[0]?.second_product_each}</span>/bottle</span>
                                                <span className="shipping-block"><span>Free shipping</span></span>
                                                </div>
                                            </div>
                                            <span className="package-btn">yes – choose this package <span>»</span></span>
                                        </div>
                                        {/* Pack 2 End  */}

                                        {/* <!-- Line Break  --> */}
                                        <div className="linebreak my-2"></div>

                                        {/* <!-- Pack 3  --> */}
                                        <div className={TPDiv ? 'package package3 package_active' : 'package package3'} onClick={() => { setOrderSummery(checkoutFunnelData[0].third_product_price, checkoutFunnelData[0].third_product_id, checkoutFunnelData[0].third_product_retail, checkoutFunnelData[0].third_product_save, checkoutFunnelData[0].third_product_shipping_id, 3, checkoutFunnelData[0].third_product_each)}}>
                                            <div className="package__left">
                                                <span className="package__name">Package 3</span>
                                                <span className="package__coll">{checkoutFunnelData?.[0]?.third_product_name}</span>
                                                <span className="package__free">{checkoutFunnelData?.[0]?.third_product_label}*</span>
                                                <p className="retail-price-mobile">Retail Price : <span className="text_red">${checkoutFunnelData?.[0]?.third_product_retail}</span><br/>
                                                Your Price : ${checkoutFunnelData?.[0]?.third_product_price}  <br/>- Save ${checkoutFunnelData?.[0]?.third_product_save}
                                                </p>
                                                <span className="package__price">
                                                    <p className="price inline-block">${checkoutFunnelData?.[0]?.third_product_each}</p>
                                                    <span>/bottle</span>
                                                </span>
                                                <span className="package__stock">In Stock – Sell Out Risk: High</span>
                                            </div>
                                            <div className="package__right">
                                                <div className="bottle-block clearfix">
                                                    <div className="packItem">
                                                        <img alt="" src={pack3} className="prod-btl1" />
                                                    </div>
                                                </div>
                                                <div className="retail-block">
                                                    <span className="package__retail ">Retail: <span className="retail-price">${checkoutFunnelData?.[0]?.third_product_each}</span>/bottle</span>
                                                    <span className="shipping-block"><span>Free shipping</span></span>
                                                </div>
                                            </div>
                                            <span className="package-btn">yes – choose this package <span>»</span></span>
                                        </div>   
                                        {/* Pack 3 end  */}
                                    </div>
                                    {/* Pack Wrapper End  */}
                                
                                </div>       
                                {/* Select Package End  */}
                                    {/* Confirm Order */}
                                    <div className={(divDisplay === 3) ? 'confirm-order' : 'confirm-order d-none'}>
                                        <p className="claimTitle text-center mb-4"><b>Your package is reserved for the next: <span id="clockdiv">{minute}:{second<10?"0"+second:second}</span></b></p>
                                        <p className="packageTitle text-center text-dark"><b> Where do we send it?</b></p>
                                        <div className="form-partial mob-check-form">
                                            <div className="shipping-form-wrapper">
                                            {isFormVisible && (
                                                <Formik
                                                    initialValues={{
                                                        firstName: localStorage.getItem('firstName'),
                                                        lastName: localStorage.getItem('lastName'),
                                                        email: localStorage.getItem('email'),
                                                        phone: localStorage.getItem('phone'),
                                                        shippingAddress1: '',
                                                        shippingZip: '',
                                                        shippingCity: '',
                                                        shippingState: '',
                                                        country:'239',
                                                        shipping_address: '',
                                                        shipping_city: '',
                                                        shipping_state: '',
                                                        shipping_zip: '',
                                                        shipping_country: ''
                                                    }}
                                                    validationSchema={Yup.object().shape({
                                                        firstName: Yup.string().required('First name is required'),
                                                        lastName: Yup.string().required('Last name is required'),
                                                        shippingAddress1: Yup.string().required('Billing address is required'),
                                                        shippingZip: Yup.string().when('country', {
                                                            is: '239',
                                                            then: Yup.string()
                                                              .required('Billing zip code is required')
                                                              .matches(/^\d{5}$/, 'Zip code must be exactly 5 digits number'),
                                                            otherwise: Yup.string().when('country', {
                                                              is: '41',
                                                              then: Yup.string()
                                                                .required('Billing zip code is required')
                                                                .matches(/^[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d$/, 'Zip code must contain only letters and numbers')
                                                                .min(6, 'Zip code should contain at least 6 characters')
                                                                .max(6, 'Zip code should contain at most 6 characters'),
                                                            }),
                                                        }),
                                                        shippingCity: Yup.string().required('Billing city is required'),
                                                        shippingState: Yup.string().required('Billing state is required'),
                                                        email: Yup.string().email("Email is required").max(255).required("Please enter a valid email"),
                                                        phone: Yup.string().required('Phone no is required')
                                                            .matches(/^[0-9]+$/, "Please enter only number!")
                                                            .min(10, 'Please enter a valid contact number!')
                                                            .max(10, 'Please enter a valid contact number!'),
                                                        country: Yup.string().required('Country is required'),
                                                    })}
                                                    onSubmit={(values, { setSubmitting }) => {
                                                        setSubmitting(true);
                                                        // alert(JSON.stringify(values, null, 2));
                                                        localStorage.setItem('firstName', values.firstName);
                                                        localStorage.setItem('lastName', values.lastName);
                                                        localStorage.setItem('email', values.email);
                                                        localStorage.setItem('phone', values.phone);
                                                        localStorage.setItem("shippingAddress1",values.shippingAddress1);
                                                        localStorage.setItem("shippingCity",values.shippingCity);
                                                        localStorage.setItem("shippingState",values.shippingState);
                                                        localStorage.setItem("shippingZip",values.shippingZip);
                                                        localStorage.setItem('country', (values.country === '239') ? 'US' : 'CA');
                                                        
                                                        prospectCreation(values);
                                                    }}
                                                >
                                                {({ errors, touched, setFieldValue }) => (    
                                                <Form className="">
                                                    <div className="shipping-form-grp">
                                                        <div className="columns form-holder">
                                                            <Field
                                                                type="text" 
                                                                className="mm-textField" 
                                                                placeholder="First Name" 
                                                                name="firstName"
                                                                />
                                                            {touched.firstName && !errors.firstName && <div className="accept-icon"/>}
                                                            <CustomErrorMsg name="firstName" />
                                                        </div>
                                                        <div className="columns form-holder">
                                                            <Field
                                                                type="text"  
                                                                className="mm-textField" 
                                                                placeholder="Last Name" 
                                                                name="lastName"
                                                                />
                                                            {touched.lastName && !errors.lastName && <div className="accept-icon"></div>}
                                                            <CustomErrorMsg name="lastName" />
                                                        </div>
                                                        <div className="columns form-holder">
                                                            <Field 
                                                                type="email" 
                                                                className="mm-textField" 
                                                                placeholder="Email Address" 
                                                                name="email"
                                                                
                                                            />
                                                            {touched.email && !errors.email && <div className="accept-icon"/>}
                                                            <CustomErrorMsg name="email" />
                                                        </div>
                                                        <div className="columns form-holder">
                                                            <Field 
                                                                type="tel" 
                                                                className="mm-textField" 
                                                                placeholder="Phone Number" 
                                                                maxLength={10} 
                                                                name="phone"
                                                                
                                                            />
                                                            {touched.phone && !errors.phone && <div className="accept-icon"/>}
                                                            <CustomErrorMsg name="phone" />
                                                        </div>
                                                        <div className="columns form-holder">
                                                            <Field 
                                                                type="text" 
                                                                className="mm-textField" 
                                                                placeholder="Billing Address" 
                                                                name="shippingAddress1"
                                                            />
                                                            {touched.shippingAddress1 && !errors.shippingAddress1 && <div className="accept-icon"/>}
                                                            <CustomErrorMsg name="shippingAddress1" />
                                                        </div>
                                                        <div className="columns form-holder">
                                                            <Field 
                                                                type="text" 
                                                                name="shippingCity" 
                                                                className="mm-textField" 
                                                                placeholder="Billing City" 
                                                            />
                                                            {touched.shippingCity && !errors.shippingCity && <div className="accept-icon"/>}
                                                            <CustomErrorMsg name="shippingCity" />
                                                        </div>
                                                        <div className="columns form-holder">
                                                            <Field
                                                                name="country"
                                                                as="select"
                                                                placeholder="Select an option"
                                                                className="mm-textField"
                                                                onChange={(event) => {
                                                                    setFieldValue('country', event.target.value);
                                                                    handleCountryChange(event);
                                                                }} 
                                                            >
                                                                <option value="239" defaultValue="239">United States</option>
                                                                <option value="41" >Canada</option>
                                                            </Field>
                                                            <CustomErrorMsg name="country" />
                                                        </div>
                                                        <div className="columns form-holder">
                                                            <Field
                                                                name="shippingState"
                                                                as="select"
                                                                className="mm-textField"
                                                            >
                                                                <option value="" >Select State</option>
                                                                {states.map((state) => (
                                                                    <option key={state.id} value={state.abbreviation}>
                                                                        {state.state}
                                                                    </option>
                                                                ))}
                                                            </Field>
                                                            <CustomErrorMsg name="shippingState" />
                                                        </div>
                                                        <div className="columns form-holder">
                                                            <Field 
                                                                type="text"
                                                                name="shippingZip" 
                                                                className="mm-textField" 
                                                                placeholder="Billing Zip Code*"
                                                                maxLength="6"
                                                            />
                                                            {touched.shippingZip && !errors.shippingZip && <div className="accept-icon"></div>}
                                                            <CustomErrorMsg name="shippingZip" />
                                                        </div>
                                                        <div className="columns form-holder">
                                                            <button type="submit" className="partialsubmitbutton"> Proceed to Checkout »</button>
                                                        </div>
                                                    </div>
                                                </Form>
                                                )}
                                                </Formik>
                                            )}
                                            </div>
                                        </div>
                                    </div>
                                    {/* Confirm Order End */}
                                    {/* Final Step – Payment Information  */}
                                    <div className={(divDisplay === 4) ? 'finalOrder' : 'finalOrder d-none'}>
                                        <p className="packageTitle text-center text-dark mb-1"><b> Final Step – Payment Information</b></p>
                                        <p className="smText text-center text-dark"><em>Your order will be processed on our secure servers</em></p>
                                        <div className="cards-list">
                                            <span>We Accept:</span>
                                            <img alt="" src={visa} />
                                            <img alt="" src={mastercard} />
                                            <img alt="" src={discover} />
                                            <img alt="" src={amex} />
                                        </div>
                                        <div className="checkout-form-wrapper mob-check-form mt-4">
                                        <Formik
                                            enableReinitialize = {true}
                                            initialValues={{
                                                card_type: '',
                                                product_id:'',
                                                cc_month: 0,
                                                cc_year: '', 
                                                ccnumber: '', 
                                                cvv: '',
                                                shipping_address: '',
                                                shipping_city: '',
                                                shipping_state: '',
                                                shipping_zip: '',
                                                shipping_country: '239',
                                                affid: affid,
                                                sid: sid,
                                                s1: s1,
                                                s2: s2,
                                                s3: s3,
                                                click_id: click_id,
                                                notes: notes,
                                                shipCheckbox:true
                                            }}
                                            validationSchema={Yup.object({
                                                cc_year: Yup.string().when("submitButton", {
                                                    is: "paypal",
                                                    then: Yup.string().notRequired(),
                                                    otherwise: Yup.string().required("Select valid year"),
                                                }),
                                                cc_month: Yup.number().when("submitButton", {
                                                    is: "paypal",
                                                    then: Yup.number().notRequired(),
                                                    otherwise: Yup.number().moreThan(0,'Select valid month').required('Select month').when('cc_year', {
                                                        is: (cc_year) => (parseInt(moment().year()) === parseInt(cc_year)) ? true : false,
                                                        then: Yup.number().moreThan(parseInt(moment().format('MM'))-1, "Select valid month")
                                                    }),
                                                }),
                                                ccnumber: Yup.string().when("submitButton", {
                                                    is: "paypal",
                                                    then: Yup.string().notRequired(),
                                                    otherwise: Yup.string().matches(/^\d+$/, 'Only numbers are allowed')
                                                    .required('Card number is required')
                                                    .max(16, 'Credit card should not be grater than 16 digits')
                                                    .min(15, 'Credit card should not be less than 15 digits'),
                                                }),
                                                cvv: Yup.string().when("submitButton", {
                                                    is: "paypal",
                                                    then: Yup.string().notRequired(),
                                                    otherwise: Yup.string().matches(/^[0-9]{3,4}$/,
                                                        'Security code must be a 3 or 4 digit number')
                                                        .required('Security code is required'),
                                                }),
                                                shipCheckbox:Yup.string(),
                                                    shipping_address: Yup.string().when("shipCheckbox", {
                                                        is: (val)=> (val==='false') ? true:false,
                                                        then: Yup.string().required("Shipping address is required")
                                                    }),
                                                    shipping_city: Yup.string().when("shipCheckbox", {
                                                        is: (val)=> (val==='false') ? true:false,
                                                        then: Yup.string().required("Shipping city is required")
                                                    }),
                                                    shipping_state: Yup.string().when("shipCheckbox", {
                                                        is: (val)=> (val==='false') ? true:false,
                                                        then: Yup.string().required("Shipping state is required")
                                                    }),
                                                    shipping_zip: Yup.string().when("shipCheckbox", {
                                                        is: (val)=> (val==='false') ? true:false,
                                                        then: Yup.string().when('shipping_country', {
                                                            is: '239',
                                                            then: Yup.string()
                                                              .required('Shipping zip code is required')
                                                              .matches(/^\d{5}$/, 'Zip code must be exactly 5 digits number'),
                                                            otherwise: Yup.string().when('shipping_country', {
                                                              is: '41',
                                                              then: Yup.string()
                                                                .required('Shipping zip code is required')
                                                                .matches(/^[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d$/, 'Zip code must contain only letters and numbers')
                                                                .min(6, 'Zip code should contain at least 6 characters')
                                                                .max(6, 'Zip code should contain at most 6 characters'),
                                                            }),
                                                        }),
                                                    }),
                                                    shipping_country: Yup.string().when("shipCheckbox", {
                                                        is: (val)=> (val==='false') ? true:false,
                                                        then: Yup.string().required("Shipping country is required")
                                                    })
                                            })}

                                            
                                            onSubmit={(values, { setSubmitting }) => {
                                                setSubmitting (true);
                                                values.product_id=selectedProduct;
                                                values.card_type = GetCardType('"' + values.ccnumber + '"');
                                                //alert(JSON.stringify(values, null, 2));
                                                if(values.shipping_address !==''){
                                                    values.billingSameAsShipping = "NO";
                                                }else {
                                                    values.billingSameAsShipping = "YES";
                                                }
                                                if(values.shipping_country === '239'){
                                                    values.shipping_country = 'US';
                                                } else {
                                                    values.shipping_country = 'CA';
                                                }
                                                if(values.submitButton === 'paypal'){
                                                    PayPalHandler(values);
                                                } else {
                                                    confirmSubmit(values);
                                                }
                                            }}
                                        >
                                            {( formProps ) => ( 
                                            <Form>
                                                <div className="formRow paymentMethod">
                                                    <div className="formDiv">
                                                        <div className={`payPal radioSec ${selectedPayment === 'paypal' ? 'expanded' : ''}`}>
                                                            <Field
                                                                type="radio"
                                                                name="contactPreference"
                                                                value="paypal"
                                                                className="rb-paypal"
                                                                id="rb-paypal"
                                                                onChange={(event) => handlePaymentSelection(event.target.value)}
                                                                checked={selectedPayment === 'paypal'}
                                                            />
                                                            <label className="label label-title" htmlFor="rb-paypal"><img src={paypal} className="paypallogo img-fluid" /></label>
                                                            <div className="label paypaldiv" htmlFor="paypaldiv">
                                                                <img src={paypalBtn} className="paypalBtn img-fluid"/>
                                                            </div>
                                                        </div>
                                                        <div className={`creditCard radioSec ${selectedPayment === 'creditCard' ? 'expanded' : ''}`}>
                                                            <Field
                                                                type="radio"
                                                                name="contactPreference"
                                                                value="creditCard"
                                                                className="rb-credit"
                                                                id="rb-credit"
                                                                onChange={(event) => handlePaymentSelection(event.target.value)}
                                                                checked={selectedPayment === 'creditCard'}
                                                            />
                                                            <label className="label label-title" htmlFor="rb-credit">Credit Card or Debit</label>
                                                            <div className="label creditdiv" htmlFor="creditdiv">
                                                                <div className="formRow row">
                                                                    <span className="label-subtitle mb-3">Visa, Mastercard, American Express, Discover</span>
                                                                    <Field type="hidden" className="form-control" id="card_type" name="card_type" value={cardType}/>
                                                                    <Field type="hidden" className="form-control" name="affid" value={affid}/>
                                                                    <Field type="hidden" className="form-control" name="sid" value={sid}/>
                                                                    <Field type="hidden" className="form-control" name="s1" value={s1}/>
                                                                    <Field type="hidden" className="form-control" name="s2" value={s2}/>
                                                                    <Field type="hidden" className="form-control" name="s3" value={s3}/>
                                                                    <Field type="hidden" className="form-control" name="click_id" value={click_id}/>
                                                                    <Field type="hidden" className="form-control" name="notes" value={notes}/>
                                                                    <div className="formCol col-12">
                                                                        <Field type="text" maxLength={16} className="form-control" placeholder="Card Number" name="ccnumber" onKeyUp={(event)=>setCreditCardType(event.target.value)}/>
                                                                        <CustomErrorMsg name="ccnumber" />
                                                                    </div>
                                                                    <div className="formCol col-12">
                                                                        <Field type="text" maxLength={4} className="form-control" placeholder="CVV" name="cvv" />
                                                                        <CustomErrorMsg name="cvv" />
                                                                    </div>
                                                                    <div className="col-12">
                                                                        <div className="row cardTime-row">
                                                                            <div className="formCol col-6">
                                                                                <Field as="select" className="form-control" name="cc_month"  >
                                                                                    <option value="0">Select Month</option>
                                                                                    <option value="01">January</option>
                                                                                    <option value="02">February</option>
                                                                                    <option value="03">March</option>
                                                                                    <option value="04">April</option>
                                                                                    <option value="05">May</option>
                                                                                    <option value="06">June</option>
                                                                                    <option value="07">July</option>
                                                                                    <option value="08">August</option>
                                                                                    <option value="09">September</option>
                                                                                    <option value="10">October</option>
                                                                                    <option value="11">November</option>
                                                                                    <option value="12">December</option>
                                                                                </Field>
                                                                                <CustomErrorMsg name="cc_month" />
                                                                            </div>
                                                                            <div className="formCol col-6">
                                                                                <Field as="select" className="form-control" name="cc_year" >
                                                                                    <option value="">Select Year</option>
                                                                                    {years.map(year => (
                                                                                        <option key={year} value={year}>
                                                                                            {year}
                                                                                        </option>
                                                                                    ))}
                                                                                </Field>
                                                                                <CustomErrorMsg name="cc_year" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-md-12 form_col mt-3'>
                                                                        <div className='masterShipAddr'>
                                                                            <label className="checkboxLabel newCustomCheckbox mb-3">
                                                                                <Field type="checkbox" id="shippingCheckbox" name="shipCheckbox" checked={checkboxValue===true ? 'checked' :""} onClick={(event) => formProps.setFieldValue("shipCheckbox", event.target.checked)}  onChange={handleCheckboxChange} />
                                                                                <span className="cus_checkBoxButton"></span>
                                                                                <span className="newCustomCheckbox_label">Shipping is the same as billing</span>
                                                                            </label>
                                                                            {showDiv &&
                                                                            <div className="form-div-2">
                                                                                <div className="form-holder">
                                                                                    <label className="formLabel">Shipping Address <span className='text-danger'>*</span></label>
                                                                                    <Field type="text" className="form-control" placeholder="Shipping Address" name="shipping_address"/>
                                                                                    <CustomErrorMsg name="shipping_address" />
                                                                                </div>
                                                                                <div className="form-holder">
                                                                                    <label className="formLabel">Shipping City <span className='text-danger'>*</span></label>
                                                                                    <Field type="text" className="form-control" placeholder="Shipping City" name="shipping_city"/>
                                                                                    <CustomErrorMsg name="shipping_city" />
                                                                                </div>
                                                                                <div className="form-holder">
                                                                                    <label className="formLabel">Shipping Country <span className='text-danger'>*</span></label>
                                                                                    <Field 
                                                                                        as="select" 
                                                                                        className="form-control" 
                                                                                        name="shipping_country"
                                                                                        onChange={(event) => {
                                                                                            formProps.handleChange(event);
                                                                                            handleCountryChange(event);
                                                                                        }}
                                                                                    >
                                                                                        <option value="239" >United States</option>
                                                                                        <option value="41" >Canada</option>
                                                                                    </Field>
                                                                                    <CustomErrorMsg name="shipping_country" />
                                                                                </div>
                                                                                <div className="form-holder">
                                                                                    <label className="formLabel">Shipping State <span className='text-danger'>*</span></label>
                                                                                    <Field 
                                                                                        as="select" 
                                                                                        className="form-control" 
                                                                                        name="shipping_state"
                                                                                    >
                                                                                    <option value="" >Select State</option>
                                                                                    {states.map((state) => (
                                                                                        <option key={state.id} value={state.abbreviation}>
                                                                                            {state.state}
                                                                                        </option>
                                                                                    ))}
                                                                                    </Field>
                                                                                    <CustomErrorMsg name="shipping_state" />
                                                                                </div>
                                                                                <div className="form-holder">
                                                                                    <label className="formLabel">Shipping Zip Code <span className='text-danger'>*</span></label>
                                                                                    <Field type="text" maxLength="6" className="form-control" placeholder="Shipping Zip Code" name="shipping_zip"/>
                                                                                    <CustomErrorMsg name="shipping_zip" />
                                                                                </div>
                                                                            </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-holder">
                                                        <button
                                                            type="submit" 
                                                            name="submitButton" 
                                                            className="partialsubmitbutton  btn_pulse" 
                                                            placeholder="" 
                                                            onClick={(event) => formProps.setFieldValue("submitButton", selectedPayment)}
                                                            value="RUSH MY ORDER!" 
                                                        >Rush My Order Now »
                                                        </button>
                                                        {/* <button 
                                                            type="submit" 
                                                            className="partialsubmitbutton  btn_pulse"> Rush My Order Now » 
                                                        </button> */}
                                                        <div className="frmCheckElemts mt-3 text-center">
                                                            <label className="spl-checkbox checkboxLabel newCustomCheckbox">
                                                                <input type="checkbox" name="emag_subscription" id="emag_subscription"  className="chkbox no-error" onChange={() => { handleSPAddon(selectedProduct) }} defaultChecked={SMAddonToggle}/>
                                                                <span className="cus_checkBoxButton"></span>
                                                                <span className="newCustomCheckbox_label">Yes, I want FREE Shipping With Sapphire Membership! </span>                           
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Old Payment Info : Begin  */}
                                                <div className="old-checkout d-none">
                                                    <div className="form-holder">
                                                        <label>Card Number:</label>
                                                        <Field type="hidden" className="form-control" id="card_type" name="card_type" value={cardType}/>
                                                        <Field type="hidden" className="form-control" name="affid" value={affid}/>
                                                        <Field type="hidden" className="form-control" name="sid" value={sid}/>
                                                        <Field type="hidden" className="form-control" name="s1" value={s1}/>
                                                        <Field type="hidden" className="form-control" name="s2" value={s2}/>
                                                        <Field type="hidden" className="form-control" name="s3" value={s3}/>
                                                        <Field type="hidden" className="form-control" name="click_id" value={click_id}/>
                                                        <Field type="hidden" className="form-control" name="notes" value={notes}/>
                                                        <Field type="text" maxLength="16" className="mm-textField" placeholder="Card Number" name="ccnumber" onKeyUp={(event)=>setCreditCardType(event.target.value)}/>
                                                        <CustomErrorMsg name="ccnumber" />
                                                    </div>
                                                    <div className="form-holder mb_form_row">
                                                        <div className="col_6">
                                                            <label>Select Month:</label>
                                                            <Field as="select" className="selectField" name="cc_month">
                                                                <option value="0">Select</option>
                                                                <option value="01">January</option>
                                                                <option value="02">February</option>
                                                                <option value="03">March</option>
                                                                <option value="04">April</option>
                                                                <option value="05">May</option>
                                                                <option value="06">June</option>
                                                                <option value="07">July</option>
                                                                <option value="08">August</option>
                                                                <option value="09">September</option>
                                                                <option value="10">October</option>
                                                                <option value="11">November</option>
                                                                <option value="12">December</option>
                                                            </Field>
                                                            <CustomErrorMsg name="cc_month" />
                                                        </div>
                                                        <div className="col_6">
                                                            <label>Select Year:</label>
                                                            <Field as="select" className="selectField" name="cc_year" >
                                                                <option value="">Select</option>
                                                                {years.map(year => (
                                                                    <option key={year} value={year}>
                                                                        {year}
                                                                    </option>
                                                                ))}
                                                            </Field>
                                                            <CustomErrorMsg name="cc_year" />
                                                        </div>
                                                    </div>
                                                    <div className="form-holder mb_form_row">
                                                        <div className="col_6">
                                                            <label>CVV Number:</label>
                                                            <Field type="text" maxLength="4" placeholder="CVV" name="cvv"/>
                                                            <CustomErrorMsg name="cvv" />
                                                        </div>
                                                        <div className="col_6">
                                                            <div className="cvv-link left">
                                                                <button onClick={handleShow}>
                                                                    What is this?
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Old Payment Info : End  */}
                                            </Form>
                                            )}
                                        </Formik> 
                                        </div>

                                        {/* Order Details Block : Begin  */}
                                        <div className="order-counter mt-4">
                                            <article className="clearfix">
                                                <div className="float_left">Product Total:</div>
                                                <div id="block2_shipping" className="right"><b>${(parseFloat(price)-(sapphirePrice)).toFixed(2)}</b></div>
                                            </article>
                                            <article className="clearfix">
                                                <div className="left">Shipping Type:</div>
                                                <div id="block2_shipping" className="right">{shipping == 0 ? <b>FREE</b> : null} Priority Shipping</div>
                                            </article>
                                            <article className="clearfix">
                                                <div className="left">Shipping Price:</div>
                                                <div id="block2_price" className="right">${shipping.toFixed(2)}</div>
                                            </article>
                                            <article id="" className="clearfix ws_vip">
                                                <div className="float_left">Sapphire Membership:</div>
                                                <div id="block2_price" className="float_right"><b>${sapphirePrice.toFixed(2)}</b></div>
                                            </article>
                                            <article className="clearfix">
                                                <div className="left">Your Total:</div>
                                                <div id="block2_total" className="right">${total.toFixed(2)}</div>
                                            </article>
                                            <article className="last clearfix">
                                                <div id="block2_total2" className="left">Retail: ${parseFloat(retail).toFixed(2)}</div>
                                                <div id="block2_save" className="right"><b>You Save: ${parseFloat(save).toFixed(2)}</b></div>
                                                <div className="clear">&nbsp;</div>
                                            </article>
                                        </div>
                                        {/* Order Details Block : End  */}

                                    </div>
                                {/* Final Step – Payment Information End   */}
                                <div className="linebreak my-4"></div>

                                <div className="secureLogo">
                                    <div className="text-center">We care about your privacy.</div>
                                    <img src={secureLogo} className="img-fluid" alt="" />
                                </div>

                                <div className="linebreak my-4"></div>

                                <div className="secureLogo">
                                    <img src={safe} className="img-fluid" alt="" />
                                </div>

                                <div className="linebreak my-4"></div>

                                <div className="secureicons">
                                    <img src={secureicons} className="img-fluid" alt="" />
                                </div>


                                


                                {/* Guranteee Block Begin  */}
                                <div className="guarantee-block">
                                    <div className="guarantee-top">30 days money back guarantee</div>
                                    <div className="guarantee-content">
                                        <img className="guarantee-icon" src={guaranteeIco} alt="" />
                                        <div className="guarantee-text">
                                            <p>We are so confident in our products and services, that we back it with a 30 days money back guarantee. If for any reason you are not fully satisfied with our products, simply return the purchased products in the original container within 30 days of when you received your order. We will refund you 100% of the purchase price – with absolutely no hassle.</p>
                                        </div>
                                    </div>
                                </div>
                                {/* Gurantrr Block End  */}

                                {/* <!-- Top Term  --> */}
                                <div className="form__in">
                                    <div className={checkoutFunnelData[0].membership_add_on == 1 ? SMToggle ? 'p-2 top-terms open' : 'top-terms' : 'd-none'}>
                                    <h1 className="top-termsHdng" onClick={() => { setSMToggle(!SMToggle) }}>Sapphire Membership Membership Terms</h1>
                                    <div className="top-trends-desc">
                                        <p>
                                            BIG SAVINGS are ahead! Sapphire Membership comes loaded with benefits. At Wholesalehealthclub we pride ourselves on offering customers the best deals on the products they love. Your Sapphire
                                            Membership takes that one step further.
                                        </p>
                                        <p>
                                            As a Sapphire Membership member we’ll send you a unique discount code each month to your inbox. Be sure to use this code at checkout to take advantage of the following benefits with your Sapphire
                                            Membership Today!:
                                        </p>
                                        <p>-FREE shipping on all orders!</p>
                                        <p>-Up to 80% off all of the great products you’ll find on wholesalehealthclub.com</p>
                                        <p>-No hassle, no questions asked replacement on any items.</p>
                                        <p>-Priority Processing – Have your orders placed in front of the line!</p>
                                        <p>-Get it all FREE for the first 14 days!</p>
                                        <p>Some Notes About Your Membership:</p>
                                        <p>
                                            Remember – while your 2 weeks of Wholesalehealthclub Sapphire Membership is free of charge, you will be billed $19.83/ month to retain membership after your first 2 weeks. If you wish to cancel
                                            your Sapphire Membership at any time, simply contact
                                            our support using the below information: Support Email: <a href="mailto:support@wholesalehealthclub.com"> support@wholesalehealthclub.com </a>
                                        </p>
                                    </div>
                                    </div>
                                </div>


                            </div>
                            {/* Left End  */}
                        </div>
                    </div>
                </div>
                

                {/* CVV Modal  */}
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <div className="cvvImg">
                            <img src={cvvImg} className="w-100" alt=""  />
                        </div>
                    </Modal.Body>
                </Modal>

                {/* Footer Begin  */}
                <footer className="footer proLand-footer">
                    <div className="container">
                        <img alt="" className="website-secure" src={websiteSecure} />
                        <ul className="soc-list row">
                            <li><img alt="" src={socialIcons} /></li>
                            <li><img alt="" src={ca} /></li>
                        </ul>

                        <p>
                            Tru Body ACV Keto Gummies is committed to maintaining the highest quality products and the utmost integrity in business practices. All products sold on this website are certified by Good Manufacturing Practices (GMP), which
                            is the highest standard of testing in the supplement industry.
                        </p>
                        <p>
                            Notice: The products and information found on this site are not intended to replace professional medical advice or treatment. These statements have not been evaluated by the Food and Drug Administration. These
                            products are not intended to diagnose, treat, cure or prevent any disease. Individual results may vary.
                        </p>
                        <p>
                            Copyright
                            ©
                            {" "+currentYear+" "}
                            Tru Body ACV Keto Gummies. All Rights Reserved.
                        </p>

                        <ul className="terms-links">
                            <li>
                                <Link to="#" onClick={(event) => {
                                    event.preventDefault();
                                    window.open('/privacy-policy', '_blank', 'toolbar=no,scrollbars=no,resizable=no,width=400,height=400');
                                }}>Privacy Policy |&nbsp;</Link>
                            </li>
                            <li>
                                <Link to="#" onClick={(event) => {
                                    event.preventDefault();
                                    window.open('/term-and-condition', '_blank', 'toolbar=no,scrollbars=no,resizable=no,width=400,height=400');
                                }}>Terms and Conditions |</Link>
                            </li>
                            <li><Link to="/contact-us">&nbsp;Contact Us</Link></li>
                        </ul>
                    </div>
                </footer>
                {/* Footer End  */}
                <div className="dScrollTop" onClick={handleClickRef}><img src={upArrow}></img></div>

            </div>
            </> : null}
            
        </>
    );
}

export default AcvMobileCheckout;
