import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Swal from 'sweetalert2';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import CustomErrorMsg from "../frontend/CustomErrorMsg";
import { useNavigate } from "react-router-dom"; // v6
import { Link } from 'react-router-dom';
import '../../assests/funnel/assets/css/checkout14.css';
import moment from "moment";
import { useLocation } from "react-router-dom";
import useStore from '../../store';
import Copyright from '../Copyright';
import {BASE_URL,stateList,GetCardType} from '../Config';

// Images 
import siteSecurity from '../../assests/funnel/assets/images/checkout14/site-security-img.png';
import pro1 from '../../assests/funnel/assets/images/checkout14/blueforrestbeauty-productlineup.png';
import pro2 from '../../assests/funnel/assets/images/checkout14/tcl.png';
import pro3 from '../../assests/funnel/assets/images/checkout14/blueforrestbeauty-productlineup.png';
import PaymentCards from '../../assests/admin/assets/img/payment_multi_cards.png';
import postalLogo from '../../assests/funnel/assets/images/checkout14/postal-logos.png';
import badge1 from '../../assests/funnel/assets/images/checkout14/security-badge-1.png';
import badge2 from '../../assests/funnel/assets/images/checkout14/security-badge-2.png';
import badge3 from '../../assests/funnel/assets/images/checkout14/security-badge-3.png';
import badge4 from '../../assests/funnel/assets/images/checkout14/security-badge-4.png';
import paymentMulti from '../../assests/funnel/assets/images/checkout14/payment_multi_secure.jpg';
import guaranteed from '../../assests/funnel/assets/images/checkout14/guaranteed.png';
import paymentMultiCards from '../../assests/funnel/assets/images/checkout14/payment_multi_cards.png';
import paymentForm from '../../assests/funnel/assets/images/checkout14/payment_form_top_bg.png';
import securityIcon1 from '../../assests/funnel/assets/images/checkout14/privacy-verified.jpg';
import securityIcon2 from '../../assests/funnel/assets/images/checkout14/certified-by.jpg';
import securityIcon3 from '../../assests/funnel/assets/images/checkout14/security-verified.jpg';
import securityIcon4 from '../../assests/funnel/assets/images/checkout14/business-verified.jpg';
import securityIcon5 from '../../assests/funnel/assets/images/checkout14/visa-mc-discover-new.png';
import securityIcon6 from '../../assests/funnel/assets/images/checkout14/postal-logos_ZKYhlZD.png';
import securityIcon7 from '../../assests/funnel/assets/images/checkout14/shop-online-lock.png';
import { useRef } from 'react';



function MasterTemplate3(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const [checkboxValue, setcheckboxValue] = useState(true);
    const [showDiv, setShowDiv] = useState(false);
    const [checkoutFunnelData, setCheckoutFunnelData] = useState({});
    const afterLogin = useStore((state) => state.afterLogin);
    const [SMToggle, setSMToggle] = useState(true);
    const [SMAddonToggle, setSMAddonToggle] = useState(true);
    const [selectedProduct, setSelectedProduct] = useState(1);
    const [cardType, setCardType] = useState("");
    const [cc_number, setcc_number] = useState("");
    
    const [price, setPrice] = useState(0);
    const [retail, setReatil] = useState(0);
    const [save, setSave] = useState(0);
    const [total, setTotal] = useState(0);
    const [shipping, setShipping] = useState(0);
    const [pID, setPID] = useState(0);
    const [FPDiv, setFPDiv] = useState(true);
    const [SPDiv, setSFPDiv] = useState(false);
    const [TPDiv, setTPDiv] = useState(false);
    const [loadershow, setloadershow] = useState(false);

    const handleCheckboxChange = (event) => {
        setcheckboxValue(event.target.checked);
        setShowDiv(!event.target.checked);
    };
    
    //State List ;
    const options = stateList;
    const [states, setStates] = useState([]);
    
    const handleCountryChange = (event) => {
        const country_id = event.target.value;
        axios.get(`api/get-state/${country_id}`).then(res => {
            if(res.data.status === true) {
                setStates(res.data.data);
            }
        })
    };

    let refCode = new URLSearchParams(location.search).get('ref');
    let affid = new URLSearchParams(location.search).get('affid');
    let sid = new URLSearchParams(location.search).get('sid');
    let s1 = new URLSearchParams(location.search).get('s1');
    let s2 = new URLSearchParams(location.search).get('s2');
    let s3 = new URLSearchParams(location.search).get('s3');
    let click_id = new URLSearchParams(location.search).get('click_id');
    let notes = new URLSearchParams(location.search).get('notes');
    let params = location.search;

    function setCreditCardType(CCNumber){
        if(CCNumber.length>=15){
            //console.log(GetCardType(CCNumber));
            setcc_number(CCNumber);
            setCardType(GetCardType(CCNumber));
            document.getElementById('card_type').value = GetCardType(CCNumber);
        }
    }

    const confirmSubmit = (data) => {
        setloadershow(true);
        let expirationDate = data.cc_month+data.cc_year.slice(-2);
        let creditCardType = GetCardType("'"+data.ccnumber+"'");
        
        const formdata = new FormData();
        formdata.append('product_id', localStorage.getItem('ProductId'));
        formdata.append('normal_product', 1);

        formdata.append('first_name', data.firstName);
        formdata.append('last_name', data.lastName);
        formdata.append('address', data.shippingAddress1);
        formdata.append('zip', data.shippingZip);
        formdata.append('state', data.shippingState);
        formdata.append('city', data.shippingCity);
        formdata.append('phone', data.phone);
        formdata.append('email', data.email);
        formdata.append('country', data.shippingCountry);

        formdata.append('creditCardNumber', data.ccnumber);
        formdata.append('expirationDate', expirationDate);
        formdata.append('cvv', data.cvv);
        formdata.append('creditCardType', data.card_type);
        formdata.append('refCode', data.refCode);
        formdata.append('affid', data.affid);
        formdata.append('sid', data.sid);
        formdata.append('s1', data.s1);
        formdata.append('s2', data.s2);
        formdata.append('s3', data.s3);
        formdata.append('click_id', data.click_id);
        formdata.append('notes', data.notes);

        formdata.append('billingSameAsShipping', data.billingSameAsShipping);
        formdata.append('shipping_address', data.shipping_address);
        formdata.append('shipping_city', data.shipping_city);
        formdata.append('shipping_state', data.shipping_state);
        formdata.append('shipping_zip', data.shipping_zip);
        formdata.append('shipping_country', data.shipping_country);

        localStorage.setItem('firstName', data.firstName);
        localStorage.setItem('lastName', data.lastName);
        localStorage.setItem('email', data.email);
        localStorage.setItem('shippingCity', data.shippingCity);
        localStorage.setItem('shippingState', data.shippingState);
        localStorage.setItem('shippingZip', data.shippingZip);
        localStorage.setItem('total', total);

        //console.log(Object.fromEntries(formdata));
        axios.post(`api/place-funnel-order`, formdata).then(res => {
            if (res.data.status === true) {
                //console.log(res.data.data.order_id);
                localStorage.setItem('orderId', res.data.data.order_id);
                localStorage.setItem('membership_orderId', res.data.membership ? res.data.membership.order_id : false);
                localStorage.setItem('authToken', res.data.member_token);
                localStorage.setItem('permission', res.data.data.user_info.account_permission);
                localStorage.setItem('userId', res.data.data.user_info.user_id);
                localStorage.setItem('userEmal', res.data.data.user_info.email);


                axios.get(`api/check_user_membership/${data.email}`).then(result => {
                    let is_membership = 0;
                    if(parseInt(result.data) === 1){
                        is_membership = 1;
                    } else {
                        is_membership = 0;
                    }
                    afterLogin(res.data.data.user_info.account_permission, res.data.data.user_info.user_type,res.data.data.user_info.user_id,res.data.data.user_info,is_membership);
                    window.location.href = '/' + localStorage.getItem('con_s')+params;
                });
                
            }
            else {
                setloadershow(false);
                Swal.fire({
                    title: res.data.message,
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33'
                })
            }
        })
    }

    const currentYear = new Date().getFullYear();
    const years = Array.from(new Array(10), (val, index) => currentYear + index);


    function setIntialData() {
        console.log(props);
        setCheckoutFunnelData(props.data);
        setPrice(props.data[0].first_product_price);
        setReatil(props.data[0].first_product_retail);
        setSave(props.data[0].first_product_save);
        setTotal(parseFloat(props.data[0].first_product_price));
        setPID((props.data[0].first_product_id))
        localStorage.setItem('ProductId',props.data[0].first_product_id);
    }

    useEffect(() => { setIntialData() }, []);

    function setDisplayPrice(productPrice, productId, productRetailPrice, productPriceSave){
        setPrice(productPrice);
        setReatil(productRetailPrice);
        setSave(productPriceSave);
        setShipping(0);
        setTotal(parseFloat(productPrice)+parseFloat(0.00));
        setPID(productId);
    }

    function setOrderSummery(productPrice, productId, productRetailPrice, productPriceSave, productShippingId, ProductDiv) {
        if(ProductDiv == 1){
            setSelectedProduct(1);
            setFPDiv(true);
            setSFPDiv(false);
            setTPDiv(false);
            localStorage.setItem('ProductId',productId);
            setDisplayPrice(productPrice, productId, productRetailPrice, productPriceSave);
        }
        if (ProductDiv == 2) {
            setSelectedProduct(2)
            setFPDiv(false);
            setSFPDiv(true);
            setTPDiv(false);
            localStorage.setItem('ProductId',productId);
            setDisplayPrice(productPrice, productId, productRetailPrice, productPriceSave);
        }
        if(ProductDiv == 3){
            setSelectedProduct(3);
            setFPDiv(false);
            setSFPDiv(false);
            setTPDiv(true);
            localStorage.setItem('ProductId',productId);
            setDisplayPrice(productPrice, productId, productRetailPrice, productPriceSave);
        }
    }

    function camelCase(str) {
        return str.split(' ').map((x) => x.charAt(0).toUpperCase() + x.slice(1).toLowerCase()).join(' ');
    }

    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const formRef = useRef(null);
    const handleRefClick = () => {
        isMobile && formRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <>

            {Object.values(checkoutFunnelData).length !== 0 ? <><div className={loadershow ? 'loading' : 'd-none'}></div>
                <div className='checkoutpage-14 '>
                    <div className="checkout-14-header-inner">
                        <div className="site-secure"><img src={siteSecurity} alt="site security" width="112" /></div>
                    </div>
                    {/* Container Begin  */}
                    <div className="container">
                        <div className="inner-logo text-center"><h2>{checkoutFunnelData[0].funnel_name}</h2></div>
                        <div className="order-banner clearfix checkout-14-order-banner">
                            {/* <!-- Left  --> */}
                            <div id="payLft" className="fntAbel payLeft">
                                <div id="" className="newCheckout-wrapper">
                                    <div id="product_1_wrapper" className="package1 plan-selection">
                                        <div id="payProd1" className={FPDiv ? 'payProd activePack' : 'payProd'} onClick={() => { setOrderSummery(checkoutFunnelData[0].first_product_price, checkoutFunnelData[0].first_product_id, checkoutFunnelData[0].first_product_retail, checkoutFunnelData[0].first_product_save, checkoutFunnelData[0].first_product_shipping_id, 1);handleRefClick() }}>
                                            <h3 className="payProdTitle">
                                                <span className="title16 fntQuantBd">
                                                    {checkoutFunnelData[0].first_product_label}
                                                </span>
                                            </h3>
                                            <div className="productDiv pb-0">
                                                <div className="productDiv-img">
                                                    <span className="radio"></span>
                                                    <div className="proImg">
                                                        <img src={BASE_URL+"whc-custom/storage/app/public/" + checkoutFunnelData[1].first_product_img} className="img-fluid product_img" alt="" />
                                                    </div>
                                                </div>
                                                <div className="productDiv-info">
                                                    <p className="proName">{camelCase(checkoutFunnelData[0].first_product_name)}</p>
                                                    <div className="div proexInfo mt-0">
                                                        <p className="price"><strong className="fntQuantBd">${checkoutFunnelData[0].first_product_price}</strong></p>
                                                        <p className="instock-info">In Stock. Current Sell-Out Risk: <span className="red">HIGH</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="product_2_wrapper" className="package2 plan-selection">
                                        <div id="payProd2" className={SPDiv ? 'payProd activePack' : 'payProd'} onClick={() => { setOrderSummery(checkoutFunnelData[0].second_product_price, checkoutFunnelData[0].second_product_id, checkoutFunnelData[0].second_product_retail, checkoutFunnelData[0].second_product_save, checkoutFunnelData[0].second_product_shipping_id, 2);handleRefClick()}}>
                                            <h3 className="payProdTitle">
                                                <span className="title16 fntQuantBd">
                                                    {checkoutFunnelData[0].second_product_label}
                                                </span>
                                            </h3>
                                            <div className="productDiv pb-0">
                                                <div className="productDiv-img">
                                                    <span className="radio"></span>
                                                    <div className="proImg">
                                                        <img src={BASE_URL+"whc-custom/storage/app/public/" + checkoutFunnelData[1].second_product_img} className="img-fluid product_img" alt="" />
                                                    </div>
                                                </div>
                                                <div className="productDiv-info">
                                                    <p className="proName">{camelCase(checkoutFunnelData[0].second_product_name)}</p>
                                                    <div className="div proexInfo mt-0">
                                                        <p className="price"><strong className="fntQuantBd">${checkoutFunnelData[0].second_product_price}</strong></p>
                                                        <p className="instock-info">In Stock. Current Sell-Out Risk: <span className="red">HIGH</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="product_3_wrapper" className="package3 plan-selection">
                                        <div id="payProd3" className={TPDiv ? 'payProd activePack' : 'payProd'} onClick={() => { setOrderSummery(checkoutFunnelData[0].third_product_price, checkoutFunnelData[0].third_product_id, checkoutFunnelData[0].third_product_retail, checkoutFunnelData[0].third_product_save, checkoutFunnelData[0].third_product_shipping_id, 3);handleRefClick()}}>
                                            <h3 className="payProdTitle">
                                                <span className="title16 fntQuantBd">
                                                    {checkoutFunnelData[0].third_product_label}
                                                </span>
                                            </h3>
                                            <div className="productDiv pb-0">
                                                <div className="productDiv-img">
                                                    <span className="radio"></span>
                                                    <div className="proImg">
                                                        <img src={BASE_URL+"whc-custom/storage/app/public/" + checkoutFunnelData[1].third_product_img} className="img-fluid product_img" alt="" />
                                                    </div>
                                                </div>
                                                <div className="productDiv-info">
                                                    <p className="proName">{camelCase(checkoutFunnelData[0].third_product_name)}</p>
                                                    <div className="div proexInfo mt-0">
                                                        <p className="price"><strong className="fntQuantBd">${checkoutFunnelData[0].third_product_price}</strong></p>
                                                        <p className="instock-info">In Stock. Current Sell-Out Risk: <span className="red">HIGH</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="order-delivery">
                                    <h3>USPS First-Class 2-3 Days Delivery</h3>
                                    <p>Parcels are shipped with insurance. Guaranteed Delivery.</p>
                                </div>
                                <div className="order-counter">
                                    <article className="clearfix">
                                        <div className="left">Shipping Type:</div>
                                        <div id="block2_shipping" className="right"><b>FREE</b> Priority Shipping</div>
                                        <div className="clear">&nbsp;</div>
                                    </article>
                                    <article className="clearfix">
                                        <div className="left">Shipping Price:</div>
                                        <div id="block2_price" className="right"><b>$0.00</b></div>
                                        <div className="clear">&nbsp;</div>
                                    </article>
                                    <article className="clearfix">
                                        <div className="left">Your Total:</div>
                                        <div id="block2_total" className="right">${total.toFixed(2)}</div>
                                        <div className="clear">&nbsp;</div>
                                    </article>
                                    <article className="last clearfix">
                                        <div id="block2_total2" className="left">Retail: ${total.toFixed(2)}</div>
                                        <div id="block2_save" className="right"><b>You Save: $0.00</b></div>
                                        <div className="clear">&nbsp;</div>
                                    </article>
                                </div>
                                <div className="order-satisfaction">
                                    <h3>Satisfaction Guarantee!</h3>
                                    <p>We’re so confident that Neuro Tech IQ will work for you that we are offering a <b>30-Days Money Back Guarantee!</b> So, feel confident that you will get results, or your money back!</p>
                                </div>
                                <div className="secu-1">
                                    <div className="row">
                                        <div className="col-md-9 mx-auto text-center">
                                            <div className="secImg-1"><img src={postalLogo} alt="" /></div>
                                            <div className="arrive-date">Your order is due to arrive on <span className="orange">undefined 12 2022</span> .</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="secu-2">
                                    <div className="row">
                                        <div className="col-lg-9 mx-auto text-center">
                                            <div className="row secu-row">
                                                <div className="col-3">
                                                    <div className="secImg-2"><img className="img-fluid" src={badge1} alt="" /></div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="secImg-2"><img className="img-fluid" src={badge2} alt="" /></div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="secImg-2"><img className="img-fluid" src={badge3} alt="" /></div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="secImg-2"><img className="img-fluid" src={badge4} alt="" /></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Left End  */}

                            {/* <!-- Right  --> */}
                            <div id="" className="formBox center-block payRight">
                                <img className="w-100" src={paymentForm} />
                                <div className="checkout14-form-div" ref={formRef}>
                                    <p className="checkout-form-title"><strong className="allCards">We Accept:</strong> <img src={paymentMultiCards} alt="Accepted Cards" width="180px" /></p>
                                    <Formik
                                        enableReinitialize = {true}
                                        initialValues={{
                                            card_type: '',
                                            product_id: '',
                                            firstName: '',
                                            lastName: '',
                                            shippingAddress1: '',
                                            shippingZip: '',
                                            shippingCity: '',
                                            shippingState: '',
                                            shippingCountry: '',
                                            email: '',
                                            phone: '',
                                            cc_month: 0,
                                            cc_year: '', 
                                            ccnumber: '', 
                                            cvv: '',
                                            shipping_address: '',
                                            shipping_city: '',
                                            shipping_state: '',
                                            shipping_zip: '',
                                            shipping_country: '',
                                            refCode : refCode,
                                            affid: affid,
                                            sid: sid,
                                            s1: s1,
                                            s2: s2,
                                            s3: s3,
                                            click_id: click_id,
                                            notes: notes 
                                        }}
                                        validationSchema={Yup.object({
                                            firstName: Yup.string().required('First name is required'),
                                            lastName: Yup.string().required('Last name is required'),
                                            shippingAddress1: Yup.string().required('Address is required'),
                                            shippingZip: Yup.string().required('Zip code is required'),
                                            shippingCity: Yup.string().required('City is required'),
                                            shippingState: Yup.string().required('State is required'),
                                            email: Yup.string().email("Email is required").max(255).required("Please enter a valid email"),
                                            phone: Yup.string().required('Phone no is required')
                                            .matches(/^[0-9]+$/, "Please enter only number!")
                                            .min(10, 'Please enter a valid contact number!')
                                            .max(10, 'Please enter a valid contact number!'),
                                            shippingCountry: Yup.string().required('Country is required'),
                                            cc_year: Yup.string().required('Select valid year'),
                                            cc_month: Yup.number().moreThan(0,'Select valid month').required('Select month').when('cc_year', {
                                                is: (cc_year) => (parseInt(moment().year()) === parseInt(cc_year)) ? true : false,
                                                then: Yup.number().moreThan(parseInt(moment().format('MM'))-1, "Select valid month")
                                            }),
                                            card_type: Yup.string(),
                                            ccnumber: Yup.string().matches(/^\d+$/, 'Only numbers are allowed')
                                            .required('Card number is required')
                                            .max(16, 'Credit card should not be grater than 16 digits')
                                            .min(15, 'Credit card should not be less than 15 digits'),
                                            cvv: Yup.string().matches(/^[0-9]{3,4}$/,
                                                'Security code must be a 3 or 4 digit number')
                                                .required('Security code is required'),
                                            shipCheckbox:Yup.string(),
                                            shipping_address: Yup.string().when("shipCheckbox", {
                                                is: (val)=> (val==='false') ? true:false,
                                                then: Yup.string().required("Shipping address is required")
                                            }),
                                            shipping_city: Yup.string().when("shipCheckbox", {
                                                is: (val)=> (val==='false') ? true:false,
                                                then: Yup.string().required("Shipping city is required")
                                            }),
                                            shipping_state: Yup.string().when("shipCheckbox", {
                                                is: (val)=> (val==='false') ? true:false,
                                                then: Yup.string().required("Shipping state is required")
                                            }),
                                            shipping_zip: Yup.string().when("shipCheckbox", {
                                                is: (val)=> (val==='false') ? true:false,
                                                then: Yup.string().required("Shipping zip is required")
                                            }),
                                            shipping_country: Yup.string().when("shipCheckbox", {
                                                is: (val)=> (val==='false') ? true:false,
                                                then: Yup.string().required("Shipping country is required")
                                            })
                                        })}

                                        onSubmit={(values, { setSubmitting }) => {
                                            setSubmitting (true);

                                            values.card_type = GetCardType('"' + values.ccnumber + '"');
                                            values.product_id=selectedProduct;
                                            //alert(JSON.stringify(values, null, 2));
                                            if(values.shipping_address !==''){
                                                values.billingSameAsShipping = "NO";
                                            }else {
                                                values.billingSameAsShipping = "YES";
                                            }
                                            if(values.shippingCountry === '239'){
                                                values.shippingCountry = 'US';
                                            } else {
                                                values.shippingCountry = 'CA';
                                            }
                                            if(values.shipping_country === '239'){
                                                values.shipping_country = 'US';
                                            } else {
                                                values.shipping_country = 'CA';
                                            }
                                            confirmSubmit(values);
                                        }}
                                    >
                                        {(formProps) => (
                                            <Form action="">
                                                <Field type="hidden" className="form-control" id="card_type" name="card_type" value={cardType}/>
                                                <Field type="hidden" className="form-control" name="affid" value={refCode}/>
                                                <Field type="hidden" className="form-control" name="affid" value={affid}/>
                                                <Field type="hidden" className="form-control" name="sid" value={sid}/>
                                                <Field type="hidden" className="form-control" name="s1" value={s1}/>
                                                <Field type="hidden" className="form-control" name="s2" value={s2}/>
                                                <Field type="hidden" className="form-control" name="s3" value={s3}/>
                                                <Field type="hidden" className="form-control" name="click_id" value={click_id}/>
                                                <div className="form-div-1">
                                                    <div className="form-holder">
                                                        <Field type="text" maxLength="16" className="form-control" placeholder="Card Number" name="ccnumber" onKeyUp={(event)=>setCreditCardType(event.target.value)}/>
                                                        <CustomErrorMsg name="ccnumber" />
                                                    </div>
                                                    <div className="form-holder">
                                                        <Field type="text" maxLength="4" className="mm-textField form-control" placeholder="CVV" name="cvv"/>
                                                        <CustomErrorMsg name="cvv" />
                                                    </div>
                                                    <div className="form-holder mb-0 cc_form-holder">
                                                        <div className="cc-month">
                                                            <label>Select Month:</label>
                                                            <Field as="select" className="form-control mm-selectList" name="cc_month"  >
                                                                <option value="0">Select Month</option>
                                                                <option value="01">January</option>
                                                                <option value="02">February</option>
                                                                <option value="03">March</option>
                                                                <option value="04">April</option>
                                                                <option value="05">May</option>
                                                                <option value="06">June</option>
                                                                <option value="07">July</option>
                                                                <option value="08">August</option>
                                                                <option value="09">September</option>
                                                                <option value="10">October</option>
                                                                <option value="11">November</option>
                                                                <option value="12">December</option>
                                                            </Field>
                                                            <CustomErrorMsg name="cc_month" />
                                                        </div>
                                                        <div className="cc-year">
                                                            <label>Select Year:</label>
                                                            <Field as="select" className="form-control" name="cc_year" >
                                                                <option value="">Select Year</option>
                                                                {years.map(year => (
                                                                    <option key={year} value={year}>
                                                                        {year}
                                                                    </option>
                                                                ))}
                                                            </Field>
                                                            <CustomErrorMsg name="cc_year" />
                                                        </div>
                                                    </div>
                                                    <div className="checkoutBtn-div">
                                                        {/* <a className="btn checkoutBtn"></a> */}
                                                        <button className="btn checkoutBtn" type='submit'></button>
                                                    </div>
                                                </div>
                                                <div className="form-div-2">
                                                    <div className="form-holder">
                                                        <Field type="text" name="firstName" className="form-control" placeholder="First Name" />
                                                        <CustomErrorMsg name="firstName" />
                                                    </div>
                                                    <div className="form-holder">
                                                        <Field type="text" name="lastName" className="form-control" placeholder="Last Name*" />
                                                        <CustomErrorMsg name="lastName" />
                                                    </div>
                                                    <div className="form-holder">
                                                        <Field type="email" name="email" className="form-control" placeholder="Email" />
                                                        <CustomErrorMsg name="email" />
                                                    </div>
                                                    <div className="form-holder">
                                                        <Field type="tel" name="phone" className="form-control" placeholder="Phone" maxLength={10} />
                                                        <CustomErrorMsg name="phone" />
                                                    </div>
                                                    <div className="form-holder">
                                                        <Field type="text" name="shippingAddress1" className="form-control" placeholder="Address" />
                                                        <CustomErrorMsg name="shippingAddress1" />
                                                    </div>
                                                    <div className="form-holder">
                                                        <Field type="text" name="shippingCity" className="form-control" placeholder="City" />
                                                        <CustomErrorMsg name="shippingCity" />
                                                    </div>
                                                    <div className="form-holder">
                                                        <Field
                                                            name="shippingCountry"
                                                            as="select"
                                                            className="form-control"
                                                            onChange={(event) => {
                                                                formProps.handleChange(event);
                                                                handleCountryChange(event);
                                                            }}
                                                        >
                                                            <option selected="selected" value="" >Select country</option>
                                                            <option value="239" >United State</option>
                                                            <option value="41" >Canada</option>
                                                        </Field>
                                                        <CustomErrorMsg name="shippingCountry" />
                                                    </div>
                                                    <div className="form-holder">
                                                        <Field
                                                            name="shippingState"
                                                            as="select"
                                                            placeholder="Select an option"
                                                            className="form-control"
                                                        >
                                                            <option value="" >Select state</option>
                                                            {states.map((state) => (
                                                                <option key={state.id} value={state.abbreviation}>
                                                                    {state.state}
                                                                </option>
                                                            ))}
                                                        </Field>
                                                        <CustomErrorMsg name="shippingState" />
                                                    </div>
                                                    <div className="form-holder">
                                                        <Field type="text" maxLength="6" name="shippingZip" className="form-control" placeholder="Zip" />
                                                        <CustomErrorMsg name="shippingZip" />
                                                    </div>
                                                    <div className="form-holder">
                                                        <label className="checkboxLabel newCustomCheckbox mb-3">
                                                            <Field type="checkbox" id="shippingCheckbox" name="shipCheckbox" checked={checkboxValue===true ? 'checked' :""} onClick={(event) => formProps.setFieldValue("shipCheckbox", event.target.checked)}  onChange={handleCheckboxChange} />
                                                            <span className="newCustomCheckbox_label">Shipping is the same as billing</span>
                                                        </label>
                                                        {showDiv &&
                                                        <div className="form-div-2">
                                                            <div className="form-holder">
                                                                <label className="formLabel">Shipping Address:</label>
                                                                <Field type="text" className="form-control" placeholder="Shipping Address" name="shipping_address"/>
                                                                <CustomErrorMsg name="shipping_address" />
                                                            </div>
                                                            <div className="form-holder">
                                                                <label className="formLabel">Shipping City:</label>
                                                                <Field type="text" className="form-control" placeholder="Shipping City" name="shipping_city"/>
                                                                <CustomErrorMsg name="shipping_city" />
                                                            </div>
                                                            <div className="form-holder">
                                                                <label className="formLabel">Shipping Country:</label>
                                                                <Field
                                                                    name="shipping_country"
                                                                    as="select"
                                                                    className="form-control"
                                                                    onChange={(event) => {
                                                                        formProps.handleChange(event);
                                                                        handleCountryChange(event);
                                                                    }}
                                                                >
                                                                    <option selected="selected" value="" >Select country</option>
                                                                    <option value="239" >United State</option>
                                                                    <option value="41" >Canada</option>
                                                                </Field>
                                                                <CustomErrorMsg name="shipping_country" />
                                                            </div> 
                                                            <div className="form-holder">
                                                                <label className="formLabel">Shipping State:</label>
                                                                <Field
                                                                    name="shipping_state"
                                                                    as="select"
                                                                    placeholder="Select state"
                                                                    className="form-control"
                                                                >
                                                                    <option value="" >Select state</option>
                                                                    {states.map((state) => (
                                                                        <option key={state.id} value={state.abbreviation}>
                                                                            {state.state}
                                                                        </option>
                                                                    ))}
                                                                </Field>
                                                                <CustomErrorMsg name="shipping_state" />
                                                            </div>
                                                            <div className="form-holder">
                                                                <label className="formLabel">Shipping Zip Code:</label>
                                                                <Field type="text" maxLength="6" className="form-control" placeholder="Shipping Zip Code" name="shipping_zip"/>
                                                                <CustomErrorMsg name="shipping_zip" />
                                                            </div>
                                                        </div>
                                                        }
                                                    </div>
                                                    <div className="checkoutBtn-div">
                                                        <button type="submit" className="btn checkoutBtn"></button>
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                                {/* <!-- Trans  --> */}
                                <div className="trans"><img loading="lazy" src={paymentMulti} alt="Safe and secure" width="100%" height="194" /></div>
                                {/* <p><!-- safe Checkout  --></p> */}
                                <div className="w_item w_item_1 section-guranteed">
                                    <div className="w_inner">
                                        <div className="w_thumb">
                                            <picture> <img className="no-lazy" src={guaranteed} alt="" /> </picture>
                                        </div>
                                        <div className="w_desc">
                                            <p>
                                                GUARANTEED <strong><span>SAFE</span></strong> CHECKOUT
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>   
                    </div>
                    {/* Container End  */}
                    {/* Security Sec Begin  */}
                    <div class="securityIcon-sec">
                        <div class="container">
                            <ul class="order-icons-list">
                                <li><img src={securityIcon1} alt="privacy verified" width="86" /></li>
                                <li><img src={securityIcon2} alt="certified" width="86" /></li>
                                <li><img src={securityIcon3} alt="security verified" width="86" /></li>
                                <li><img src={securityIcon4} alt="business verified" width="85" /></li>
                                <li>
                                    <img loading="lazy" src={PaymentCards} alt="Visa, Mastercard, Discover accepted" width="160" height="36" />
                                </li>
                                <li><img src={securityIcon6} alt="united states" width="299" /></li>
                                <li><img src={securityIcon7} alt="shop online" width="187" /></li>
                            </ul>
                        </div>
                    </div>
                    {/* Security Sec End  */}
                </div>

                {/* Footer  */}
                <Copyright/>
                {/* Footer End         */}

            </> : null}

        </>

    )
}

export default MasterTemplate3